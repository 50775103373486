import { all } from 'redux-saga/effects';

import { combineReducers } from 'redux';
import { metronic } from '../../_metronic';

import * as auth from './ducks/auth.duck';
import * as stores from './ducks/stores.duck';
import * as addons from './ducks/addons.duck';
import * as riders from './ducks/riders.duck';
import * as markers from './ducks/markers.duck';
import * as customers from './ducks/customers.duck';

// --

import * as _towns from './ducks/_towns.duck';
import * as _users from './ducks/_users.duck';
import * as _shops from './ducks/_shops.duck';
import * as _riders from './ducks/_riders.duck';
import * as _orders from './ducks/_orders.duck';
import * as _markers from './ducks/_markers.duck';
import * as _products from './ducks/_products.duck';
import * as _expenses from './ducks/_expenses.duck';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,

  riders: riders.reducer,
  stores: stores.reducer,
  addons: addons.reducer,
  markers: markers.reducer,
  customers: customers.reducer,

  // --

  _towns: _towns.reducer,
  _users: _users.reducer,
  _shops: _shops.reducer,
  _riders: _riders.reducer,
  _orders: _orders.reducer,
  _markers: _markers.reducer,
  _products: _products.reducer,
  _expenses: _expenses.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    stores.saga(),
    addons.saga(),
    riders.saga(),
    markers.saga(),
    customers.saga(),

    // --

    _towns.saga(),
    _users.saga(),
    _shops.saga(),
    _riders.saga(),
    _orders.saga(),
    _markers.saga(),
    _products.saga(),
    _expenses.saga(),
  ]);
}
