/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { customAlphabet } from 'nanoid'


import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../partials/content/Portlet';

import {
  Col, Row,
  Form, Toast, Modal, Table,
  Button, Dropdown, Container
} from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as duck from '../../store/ducks/_riders.duck'
import Pagination from '../../partials/component/Pagination';

// TODO: this is temporary pull it from DB
const AREAS = [
  'PH-SOR-BLN',
  'PH-SOR-BAR',
  'PH-SOR-BSN',
  'PH-SOR-CSG',
  'PH-SOR-CST',
  'PH-SOR-DON',
  'PH-SOR-GUB',
  'PH-SOR-IRO',
  'PH-SOR-JUB',
  'PH-SOR-MGL',
  'PH-SOR-MAT',
  'PH-SOR-PLR',
  'PH-SOR-PDZ',
  'PH-SOR-SOR',
  'PH-SOR-SMG'
];

// TODO: this is temporary pull it from DB
const COURIERS = [
  { _id: 'm8TDf9JgiAKQIkORjVe6Q',  name: 'Local Elves' },
  { _id: 'SKGnqLeB_4bzuRBiL88TQ',  name: 'Bulan Food Delivery' },
  { _id: 'tBUTtsVKEatoC1UcU8IjL',  name: 'Rider Group 1' },
  { _id: 'VWSut3_UcYA6AZ9utiQj7',  name: 'Rider Group 2' },
  { _id: 'GI1ebuj6pL4xKueRb5fhC',  name: 'Rider Group 3' },
  { _id: 'NnwCdixkYis6_Tv9TPxRD',  name: 'Rider Group 4' },
]

function Riders (props) {
  const { getRecords, patchRecord, riders, toastClear, doSearch } = props;
  const currentPage = riders.page || 1;

  const [showApproval, setShowApproval] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRider, setSelectedRider] = useState(null);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerObj, setCustomerObj] = useState(null);

  // -- hooks

  useEffect(() => {
    getRecords(currentPage)
  }, [getRecords, currentPage])

  // -- actions

  const initiateBanning = (_id) => {
    setModalVisible(true)
    setSelectedRider(_id)
  }

  const initiateApproval = (_id) => {
    setShowApproval(true)
    setSelectedRider(_id)
  }

  const banRider = (banReason) => {
    if (!selectedRider) return

    patchRecord(selectedRider, {
      active: false,
      banReason
    })

    setModalVisible(false)
  }

  const onEditCustomer = (customer) => {
    setCustomerObj(customer)
    setShowCustomerModal(true)
  }

  const handleModalSubmit = (data) => {
    if (customerObj) {
      patchRecord(customerObj._id, data)
    }
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        patchRecord(currentPage)
      }
    }
  }

  const appoveRider = (values) => {
    if (!selectedRider) return
    patchRecord(selectedRider, values)
    setShowApproval(false)
  }

  // -- render

  return (
    <>
      <BanReasonModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        onSubmit={reason => banRider(reason)}
      />

      <ApprovalModal
        show={showApproval}
        onHide={() => setShowApproval(false)}
        onSubmit={values => appoveRider(values)}
      />

      <CustomerModal
        show={showCustomerModal}
        customer={customerObj}
        onHide={(cb) => setShowCustomerModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Riders{' '}
              <small>Global rider manager.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Rider" onKeyPress={handlePressEnter} />
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>fbun ({riders.count})</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Allowance</th>
                <th>Verification</th>
                <th>isActive</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                riders.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <Link to={`/_riders/${item._id}/${item.name}`} className="kt-footer__menu-link kt-link">
                          {item.fbun ? item.fbun : item._id.substr(0, 6)}
                        </Link>
                      </td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditCustomer(item)}>
                          {item.name}
                        </a>
                        
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item?.allowance || 0}</td>
                      <td>
                        {
                          item.verified
                            ? <span className="kt-font-success">VERIFIED ({item.refCode})</span>
                            : <span className="kt-font-info">PENDING ({item.refCode || 'NOT SET'})</span>
                        }
                      </td>
                      <td>
                        {
                          item.active
                            ? <span className="kt-font-success">Active</span>
                            : <span className="kt-font-danger">Inactive ({item.banReason})</span>
                        }
                      </td>
                      <td>
                        {
                          item.online
                            ? <span className="kt-font-success">ONLINE</span>
                            : <span className="kt-font-danger">OFFLINE</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {
                              item.active ? (
                                <>
                                  <Dropdown.Item onClick={() => initiateBanning(item._id)}>Ban/Deactivate</Dropdown.Item>
                                  { !item.verified && <Dropdown.Item onClick={() => initiateApproval(item._id)}>Approve</Dropdown.Item>}
                                  {
                                    item.online ? (
                                      <Dropdown.Item onClick={() => patchRecord(item._id, { online: false })}>Set as Offline</Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item onClick={() => patchRecord(item._id, { online: true })}>Set as Online</Dropdown.Item>
                                    )
                                  }
                                </>
                              ) : (
                                <Dropdown.Item onClick={() => patchRecord(item._id, { active: true })}>Activate</Dropdown.Item>
                              )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={riders.page}
              pages={riders.pages}
              onPageClick={getRecords}
            />
          </div>
        
        </PortletBody>
      </Portlet>

      <div style={{ position: 'absolute', top: -20, right: 45 }}>
        <Toast onClose={() => toastClear()} show={!!riders.toastErr} delay={1000 * 6} autohide>
          <Toast.Header>
            <i className="flaticon-warning kt-font-danger"></i>
            <strong className="mr-auto">&nbsp;&nbsp;{riders.toastErr?.error}</strong>
          </Toast.Header>
          <Toast.Body>{riders.toastErr?.message}</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

function BanReasonModal(props) {
  const [reason, setReason] = useState('')

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Enter Banning Reason</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>Reason</Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder="Reason.."
                  onChange={e => setReason(e.target.value)}
                />
              </Col>

            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit(reason)} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ApprovalModal(props) {
  const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);
  const [refCode] = useState(nanoid())
  const [area, setArea] = useState(AREAS[0])
  const [group, setGroup] = useState(COURIERS[0]._id)

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Rider Needed Info</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Approval Code</Form.Label>
              <Col sm={8}><Form.Control defaultValue={refCode} readOnly /></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={4}>Route/Area</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" onChange={e => setArea(e.target.value)}>
                  {AREAS.map(area => (
                    <option key={area} value={area}>{area}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={4}>Rider Group</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" onChange={e => setGroup(e.target.value)}>
                  {COURIERS.map(courier => (
                    <option key={courier._id} value={courier._id}>{courier.name}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit({ refCode, area, courierGroup: group })} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CustomerModal (props) {
  const { customer } = props

  const dispatch = useDispatch();

  const [name, setName] = useState('')
  const [fbun, setFbun] = useState('')
  const [allowance, setAllowance] = useState(0)

  const [stat, setStat] = useState(null)

  const { riderStat } = useSelector(state => ({
    riderStat: state._riders.riderStat,
  }))

  useEffect(() => {
    if (customer) {
      setName(customer.name)
      setFbun(customer.fbun || '')
      setAllowance(customer.allowance || 0)

      dispatch({ type: 'GET_RIDER_STAT', userId: customer._id })

      setTimeout(() => {
        const ud = riderStat.get(customer._id)
        setStat(ud)
      }, 1000)
    }
  }, [dispatch, customer, riderStat]);

  const handleSubmit = () => {
    const capturedValues = {
      fbun,
      allowance: +allowance
    }

    if (customer) {
      props.onSubmit(capturedValues)
    }

    handleHide()
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleHide = () => {
    setName('')
    props.onHide()
  }

  const onAck = () => {
    if (window.confirm('THIS WILL RESET EXPENSES AND CLEAR RIDER JOB ORDER LIST!')) {
      dispatch({ type: 'ACK_RIDER_STAT', userId: customer._id })

      setTimeout(() => {
        const ud = riderStat.get(customer._id)
        setStat(ud)
      }, 900)
    }
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Customer <small>{customer?._id}</small></Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} disabled/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>FB Username</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Facebook Username" onChange={(e) => setFbun(e.target.value)} value={fbun} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Allowance</Form.Label>
              <Col sm={9}><Form.Control type="number" placeholder="Allowannce" onChange={(e) => setAllowance(e.target.value)} value={allowance} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Stats</Form.Label>
              <Col sm={9}>
                <code>No. of Served Orders: {stat?.count ?? 0}</code><br/>
                <code>Total Delivery Fee: {stat?.totalDeliveryFee ?? 0}</code><br/>
                <code>Total Markup: {stat?.totalMarkup ?? 0}</code><br/>
                <code>Total Expenses: {stat?.totalExpense ?? 0}</code><br/>
                <code>Cash on Hand: {(allowance + stat?.totalDeliveryFee + stat?.totalMarkup) - stat?.totalExpense ?? 0}</code>
              </Col>
            </Form.Group>

          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <Button onClick={onAck} variant="secondary" className="btn-sm">Acknowledge</Button>

        <div>
          <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ customer ? 'Save' : 'Submit'}</Button>&nbsp;&nbsp;
          <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		riders: state._riders,
	}
}

export default connect(mapStateToProps, duck.actions)(Riders);
