import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, takeEvery } from 'redux-saga/effects';

import * as crud from '../../crud/_markers.crud'

const initialState = {
  lookup: new Map(),
}

const persistConfig = {
  storage,
  key: 'elves-markers',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._orders.page

// -- action types

export const actionTypes = {
  MARKERS_LKUP: 'MARKERS_LKUP',
  MARKERS_LKUP_DONE: 'MARKERS_LKUP_DONE',
  MARKERS_LKUP_LOAD: 'MARKERS_LKUP_LOAD',
}

// -- action

export const actions = {
  lookup: (query) => ({ type: actionTypes.MARKERS_LKUP, query }),
  fillLookups: records => ({ type: actionTypes.MARKERS_LKUP_LOAD, records }),
};

// -- sagas

export function* saga() {
  yield takeEvery(actionTypes.MARKERS_LKUP, function* lookupSaga(action) {
    try {

      const { data: records } = yield crud.lookup(action.query);
      
      yield put(actions.fillLookups(records));
      yield put({ type: actionTypes.MARKERS_LKUP_DONE })
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {

    // --

    case actionTypes.MARKERS_LKUP_LOAD: {
      const { lookup } = state

      action.records.forEach(record => {
        lookup.set(record._id, record)
      });

      return { ...state, lookup }
    }

    // --

    default:
      return state;
  }
});
