/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import {
	Modal, Button,
  Form, Col, Row, Container,
} from 'react-bootstrap';

import { TEMP_PHOTO, TEMP_SHOP_ADDRESS } from '../../../../config';

function ShopModal (props) {
  const { shop } = props
  const [errorMsg, setErrorMsg] = useState('')

  const [name, setName] = useState(shop?.name || '')
  const [type, setType] = useState(shop?.type || 'foods')
  const [desc, setDesc] = useState(shop?.description || '')
  const [cats, setCats] = useState(shop?.categories.join(', ') || '')
  const [photo, setPhoto] = useState(shop?.photo || TEMP_PHOTO)
  const [rank, setRank] = useState(shop?.rank || 99)
  const [phone, setPhone] = useState(shop?.phone || '09000000000')

  const [storeOpen, setStoreOpen] = useState(shop?.storeHours.length ? shop?.storeHours[0] : 1000)
  const [storeClose, setStoreClose] = useState(shop?.storeHours.length ? shop?.storeHours[1] : 2100)

  useEffect(() => {
    if (shop) {
      setName(shop.name)
      setType(shop.type)
      setRank(shop.rank)
      setPhoto(shop.photo)
      setPhone(shop.phone)
      setDesc(shop.description)
      setCats(shop.categories.join(', '))
      setStoreOpen(shop.storeHours.length ? shop.storeHours[0] : 1000)
      setStoreClose(shop.storeHours.length ? shop.storeHours[1] : 2100)
    }
  }, [shop]);

  // -- user actions

  const handleSubmit = () => {
    if (!name) {
      setErrorMsg('Please enter product name.')
      return
    }

    if (!desc) {
      setErrorMsg('Please enter product description.')
      return
    }

    if (!storeOpen || !storeClose) {
      setErrorMsg('Please enter a valid shop hours.')
      return
    }

    setErrorMsg('')

    const capturedValues = {
      name,
      type,
      photo,
			phone,
      description: desc,
      categories: cats.split(',').map(s => s.trim()),
      rank: +rank || 99,
    }

    if (shop.storeHours.length) {
      capturedValues.storeHours = [+storeOpen, +storeClose]
    }

    if (shop) {
      props.onSubmit(capturedValues)
    } else {
      props.onSubmit({
        ...capturedValues,
  
        active: false,
				courier: 'elves',
				schedOption: 'custom',
				schedDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
				serviceTypes: ['DELIVER'],
				address: TEMP_SHOP_ADDRESS,
        coordinates: [ 123.87516345680879,  12.658796789847624]
      })
    }

    handleHide()
  }

  const handleHide = () => {
    setName('')
    setDesc('')
    setCats('')
    setStoreOpen(1000)
    setStoreClose(2100)
    setPhone('09000000000')
    props.onHide()
  }

  const handleEnterKey = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="lg" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{ shop ? 'Edit' : 'Add New'} Shop { !!shop && (<small> / {shop._id}</small>)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        { !!errorMsg && <code>{errorMsg}</code>}

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Store Name</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Store Name" onChange={(e) => setName(e.target.value)} value={name} /></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Description</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Description" onChange={(e) => setDesc(e.target.value)} value={desc} onKeyPress={handleEnterKey}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Menu Cat</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Comma delimited." onChange={(e) => setCats(e.target.value)} value={cats} onKeyPress={handleEnterKey}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Photo</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Photo" onChange={(e) => setPhoto(e.target.value)} value={photo} onFocus={(event) => event.target.select()}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Store Hours</Form.Label>
              <Col sm={4}>
                <Row>
                  <Col><Form.Control type="number" onChange={(e) => setStoreOpen(e.target.value)} value={storeOpen} /></Col>
                  <Col><Form.Control type="number" onChange={(e) => setStoreClose(e.target.value)} value={storeClose} /></Col>
                </Row>
              </Col>

              <Col sm={1}></Col>
              <Form.Label column sm={1}>Rank</Form.Label>
              <Col sm={3}>
                <Form.Control type="text" placeholder="Rank" onChange={(e) => setRank(e.target.value)} value={rank} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Phone</Form.Label>
              <Col sm={4}>
                <Row>
                  <Col><Form.Control type="text" onChange={(e) => setPhone(e.target.value)} value={phone} /></Col>
                </Row>
              </Col>

							<Col sm={1}></Col>
              <Form.Label column sm={1}>Type</Form.Label>
              <Col sm={3}>
								<Form.Control as="select" onChange={(e) => setType(e.target.value)} value={type}>
									<option key="foods">foods</option>
									<option key="tangibles">tangibles</option>
									<option key="consumables">consumables</option>
								</Form.Control>
              </Col>
            </Form.Group>

            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={2}>Order Type</Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-dine-in" label="DINE IN" />
                      <Form.Check type="checkbox" id="checkbox-take-out" label="TAKE OUT" />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-pick-up" label="PICK UP" />
                      <Form.Check type="checkbox" id="checkbox-deliver" label="DELIVER" />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ shop ? 'Save' : 'Submit'}</Button>
        <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShopModal;
