import React from 'react';

export default function Pagination ({ page, pages, onPageClick: moveToPage }) {
  const buttons = [];
  const secondaryClass = 'btn btn-icon btn-sm btn-secondary mr-2 my-1';
  const defaultClass = 'btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1';

  const prevPage = page - 1 || 1;
  const nextPage = page + 1 > pages ? pages : page + 1;

  const maxPageBtn = 5

  let startNum = (page - 2) > 0 ? page - 2 : 1;
  let endNum = (page + 2) < pages ? page + 2 : pages;

  if (endNum < maxPageBtn && maxPageBtn < pages) {
    endNum = maxPageBtn
  }

  if (pages > 4) {
    let deficit = endNum - startNum

    if (deficit < 4) {
      const proposed = startNum - (4 - deficit)
      startNum = proposed > 0 ? proposed : startNum
    }
  }
  
  if (startNum > 1) {
    buttons.push(<button key={startNum-1} onClick={() => moveToPage(startNum-1)} className={defaultClass} >...</button>)
  }

  for (let i = startNum; i <= endNum; i++) {
    buttons.push(
      <button
        key={i}
        onClick={() => moveToPage(i)}
        className={i === page ? `${defaultClass} active` : `${defaultClass}`}
      >{i}</button>
    )
  }

  if (endNum < pages) {
    buttons.push(<button key={endNum+1} onClick={() => moveToPage(endNum+1)} className={defaultClass} >...</button>)
  }

  return (
    <div className='d-flex flex-wrap'>
      <button className={secondaryClass} onClick={() => moveToPage(1)}>«</button>
      <button className={secondaryClass} onClick={() => moveToPage(prevPage)}>‹</button>
        {buttons}
      <button className={secondaryClass} onClick={() => moveToPage(nextPage)}>›</button>
      <button className={secondaryClass} onClick={() => moveToPage(pages)}>»</button>
    </div>
  )
};