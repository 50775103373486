import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_products.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),

  isLoading: true
}

const persistConfig = {
  storage,
  key: 'elves-products',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._products.page

// -- action types

export const actionTypes = {
  PRODUCT_ADD: 'PRODUCT_ADD',
  PRODUCTS_LOAD: 'PRODUCTS_LOAD',
  PRODUCTS_GET: 'PRODUCTS_GET',

  PRODUCT_UPD: 'PRODUCT_UPD',
  PRODUCT_LOAD: 'PRODUCT_LOAD',

  PRODUCTS_QUERY_REQ: 'PRODUCTS_QUERY_REQ',
}

// -- action

export const actions = {
  addRecord: payload => ({ type: actionTypes.PRODUCT_ADD, payload }),

  getRecords: query => ({ type: actionTypes.PRODUCTS_GET, query }),
  fillRecords: response => ({ type: actionTypes.PRODUCTS_LOAD, response }),

  patchRecord: (_id, data) => ({ type: actionTypes.PRODUCT_UPD, payload: { _id, data } }),
  fillRecord: payload => ({ type: actionTypes.PRODUCT_LOAD, payload }),

  doQuery: query => ({ type: actionTypes.PRODUCTS_QUERY_REQ, query }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.PRODUCT_ADD, function* postSaga(action) {
    try {
      const { data: response } = yield crud.post(action.payload)
      yield put(actions.fillRecord(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.PRODUCTS_GET, function* querySaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.query({ ...action.query, page})

      yield put(actions.fillRecords(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.PRODUCT_UPD, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillRecord(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.PRODUCTS_QUERY_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.query(action.query)
      yield put(actions.fillRecords(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCTS_LOAD: {
      const { page, pages, count, data } = action.response;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    // --

    case actionTypes.PRODUCT_LOAD: {
      const { _id } = action.payload
      const { list } = state

      list.set(_id, action.payload)

      return { ...state, list, isLoading: false, }
    }

    default:
      return state;
  }
});
