import React, { useEffect } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tduck from '../../store/ducks/_towns.duck';
import * as mduck from '../../store/ducks/markers.duck';

import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { WEB_MAP_KEY } from '../../../config';

const containerStyle = {
  width: '1100px',
  height: '700px'
};

const center = {
  // Bulan
  lat: 12.66624395130236,
  lng: 123.87889442889991

  // Irosin
  // lng: 124.034507151422, 
  // lat: 12.7039902473145
};

const markerLabelObj = {
  className: "map-marker-label",
  color: '#ffffff',
}

function MapMarkers(props) {
  const { _mduck, _tduck, markers, towns } = props;

  // -- hooks

  useEffect(() => {
    _mduck.getMarkers()
    _tduck.getRecords()
  }, [_mduck, _tduck])

  // -- render

  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            <>
              Map Markers{" "}
              <small>Marker or pins that will be used when calculating delivery fees.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <a
                href="/#"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
              >
                Add New
              </a>
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <LoadScript googleMapsApiKey={WEB_MAP_KEY}>
            <GoogleMap
              options={{ mapTypeId: 'satellite'}}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={16}
            >
              {
                Array.from(markers.list.values()).map((item, index) => {
                  return <Marker
                    key={index}
                    label={{ ...markerLabelObj, text: item.name }}
                    position={{ lat: item.coordinates[1], lng: item.coordinates[0] }}
                  />
                })
              }

              {
                towns && Array.from(towns.list.values()).map((item, index) => {
                  if (!item.fence) return undefined;
                  return <Polyline
                    key={index}
                    path={item.fence.map(coor => ({ lat: coor[0], lng: coor[1] }))}
                    geodesic={true}
                    options={{
                      strokeColor: item.fenceColor || 'cyan',
                      strokeOpacity: 0.75,
                      strokeWeight: 2,
                    }}
                  />
                })
              }
              {/* <Polyline 
                path={[
                  { lat: 12.81974673310464, lng: 123.74573447225022 },
                  { lat: 12.917979970666853, lng: 124.18898705053594 },
                  { lat: 12.522353304811919, lng: 124.15786825056445 },
                  { lat: 12.480832644068093, lng: 123.97737921073002 },
                  { lat: 12.81974673310464, lng: 123.74573447225022 },
                ]}
                geodesic={true}
                options={{
                  strokeColor: "cyan",
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
                }}
              /> */}
            </GoogleMap>
          </LoadScript>
        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
    towns: state._towns,
		markers: state.markers,
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    _mduck: bindActionCreators(mduck.actions, dispatch),
    _tduck: bindActionCreators(tduck.actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapMarkers);
