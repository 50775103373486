/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../partials/content/Portlet';

import {
  Col, Row,
  Form, Toast, Modal, Table,
  Button, Dropdown, Container,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import * as duck from '../../store/ducks/customers.duck'
import Pagination from '../../partials/component/Pagination';

function Customers(props) {
  const { getCustomers, patchCustomer, customers, toastClear, doSearch } = props;
  const currentPage = customers.page || 1;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerObj, setCustomerObj] = useState(null);

  // -- hooks

  useEffect(() => {
    getCustomers(currentPage)
  }, [getCustomers, currentPage])

  // -- user actions

  const updateUserStatus = (_id, status) => {
    patchCustomer(_id, {
      isActive: true,
      status,
    })
  }

  const initiateBanning = (_id) => {
    setModalVisible(true)
    setSelectedUser(_id)
  }

  const banUser = (banReason) => {
    if (!selectedUser) return

    patchCustomer(selectedUser, {
      status: 'BANNED',
      isActive: false,
      banReason
    })

    setModalVisible(false)
  }

  const onEditCustomer = (customer) => {
    setCustomerObj(customer)
    setShowCustomerModal(true)
  }

  const handleModalSubmit = (data) => {
    if (customerObj) {
      patchCustomer(customerObj._id, data)
    }
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        getCustomers(currentPage)
      }
    }
  }

  // -- render

  return (
    <>
      <BanReasonModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        onSubmit={reason => banUser(reason)}
      />

      <CustomerModal
        show={showCustomerModal}
        customer={customerObj}
        onHide={(cb) => setShowCustomerModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Customers{' '}
              <small>Global customer manager.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Customer" onKeyPress={handlePressEnter} />
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>fbun ({customers.count})</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Adress</th>
                <th>Txn</th>
                <th>Status</th>
                <th>isActive</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                customers.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditCustomer(item)}>
                          {item.fbun ? item.fbun : item._id.substr(0, 6)}
                        </a>
                      </td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;
                        {item.name}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.address}</td>
                      <td>{item.txnCount}</td>
                      <td>
                        {
                          item.status === 'VERIFIED'
                            ? <span className="kt-font-success">{item.status}</span>
                            : <span className="kt-font-danger">{item.status}</span>
                        }
                      </td>
                      <td>
                        {
                          item.isActive
                            ? <span className="kt-font-success">Active</span>
                            : <span className="kt-font-danger">Not Active</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {
                              item.status === 'VERIFIED' ? (
                                <Dropdown.Item onClick={() => initiateBanning(item._id)}>Ban User</Dropdown.Item>
                              ) : (
                                <>
                                  <Dropdown.Item onClick={() => updateUserStatus(item._id, 'VERIFIED')}>Verify</Dropdown.Item>
                                  <Dropdown.Item onClick={() => updateUserStatus(item._id, 'UNREACHABLE')}>Unreachable</Dropdown.Item>
                                </>
                              )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={customers.page}
              pages={customers.pages}
              onPageClick={getCustomers}
            />
          </div>
        
        </PortletBody>
      </Portlet>

      <div style={{ position: 'absolute', top: -20, right: 45 }}>
        <Toast onClose={() => toastClear()} show={!!customers.toastErr} delay={1000 * 6} autohide>
          <Toast.Header>
            <i className="flaticon-warning kt-font-danger"></i>
            <strong className="mr-auto">&nbsp;&nbsp;{customers.toastErr?.error}</strong>
            {/* <small>Code: {users.toastErr?.code}</small> */}
          </Toast.Header>
          <Toast.Body>{customers.toastErr?.message}</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

function BanReasonModal(props) {
  const [reason, setReason] = useState('')

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Enter Banning Reason</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>Reason</Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder="Reason.."
                  onChange={e => setReason(e.target.value)}
                />
              </Col>

            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit(reason)} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CustomerModal (props) {
  const { customer } = props

  const [name, setName] = useState('')
  const [fbun, setFbun] = useState('')

  useEffect(() => {
    if (customer) {
      setName(customer.name)
      setFbun(customer.fbun || '')
    }
  }, [customer]);

  const handleSubmit = () => {
    const capturedValues = {
      fbun,
    }

    if (customer) {
      props.onSubmit(capturedValues)
    }

    handleHide()
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleHide = () => {
    setName('')
    props.onHide()
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Customer <small>{customer?._id}</small></Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              {/* <Col sm={4}> */}
                <div className="kt-widget5__pic">
                  <img alt="" className="kt-widget7__img order-detail-image" src={customer?.photo} />
                </div>
              {/* </Col> */}
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} disabled/></Col>
            </Form.Group>

            

            <Form.Group as={Row}>
              <Form.Label column sm={3}>FB Username</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Facebook Username" onChange={(e) => setFbun(e.target.value)} value={fbun} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ customer ? 'Save' : 'Submit'}</Button>
        <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		customers: state.customers,
	}
}

export default connect(mapStateToProps, duck.actions)(Customers);
