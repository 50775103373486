/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";

import {
  Col, Row,
  Form, Modal,
  Table, Dropdown, Spinner,
  Button, Container,
} from "react-bootstrap";


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as sduck from '../../../store/ducks/stores.duck'
import * as pduck from '../../../store/ducks/products.duck'

import SelectSearch from 'react-select-search'
import Pagination from '../../../partials/component/Pagination'

const DEFAULT_PHOTO = 'https://res.cloudinary.com/higher-elves/image/upload/v1626616771/products/default-food_qp0zvj_ex0ybd.webp'

function Products(props) {
  const { _pduck, _sduck, products, stores } = props;

  const [store, setStore] = useState('')
  const [searchText, setSearchText] = useState('')
  const [category, setCategory] = useState('')
  const [page, setPage] = useState(products.page || 1)

  const [storeObj, setStoreObj] = useState(null)
  const [categories, setCategories] = useState([])

  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [editProduct, setEditProduct] = useState(null)

  // -- hooks

  useEffect(() => {
    if (!options.length) {
      _sduck.doQuery({ isActive: true, limit: 100 })

      if (stores.list.size) {
        const arrStores = Array.from(stores.list.values())

        const activeStores = arrStores
          .map(({ _id, name, isActive }) => isActive ? { value: _id, name } : null)
          .filter(Boolean)

        if (arrStores.length === activeStores.length) {
          setOptions(activeStores)
        }
      }
    }

    if (storeObj) {
      setCategories(storeObj.menuCategories)
    } else {
      _pduck.doQuery({ page }) 
    }
  }, [_pduck, _sduck, page, options, stores, storeObj])

  // -- user actions

  const setSoldOut = (_id, soldOut) => {
    _pduck.patchProduct(_id, { soldOut })
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      setPage(1)
      setSearchText(text)
      _pduck.doQuery({ store, search: text, page: 1, category })
    }
  }

  const onSelectChange = (store) => {
    setPage(1)
    setStore(store)
    _pduck.doQuery({ store, search: searchText, page: 1 })

    setStoreObj(stores.list.get(store))
  }

  const onCategoryChange = (category) => {
    setCategory(category)
    _pduck.doQuery({ store, search: searchText, page: 1, category })
  }

  const onPageClick = (page) => {
    _pduck.doQuery({ store, search: searchText, page, category })
  }

  const onEditProduct = (product) => {
    setEditProduct({ ...product })
    setShowModal(true)
  }

  const handleModalSbmit = (data) => {
    if (editProduct) {
      _pduck.patchProduct(editProduct._id, data)
    } else {
      _pduck.addProduct(data)
    }
  }

  // -- render

  return (
    <>
      <ProductDetailModal
        show={showModal}
        options={options}
        store={store}
        stores={stores}
        product={editProduct}
        onHide={() => setShowModal(false)}
        onSubmit={data => handleModalSbmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Product List{" "}
              { store ? (
                <small>/ {store}</small>
              ) : (
                <small>All registered store products.</small>
              )}
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              { !!categories?.length && (
                <div className="row">
                  <div className="col-sm-4">
                    <SelectSearch
                      options={categories.map((cat) => ({ name: cat, value: cat }))} 
                      placeholder="Choose Category" 
                      value={categories[0]}
                      onChange={onCategoryChange}
                    />
                  </div>
                </div>
              )}
              &nbsp;
              <div className="row">
                <div className="col-sm-6">
                  <SelectSearch
                    options={options} 
                    placeholder="Choose Store" 
                    onChange={onSelectChange}
                  />
                </div>
              </div>
              &nbsp;
              <Form.Control size="sm" type="text" placeholder="Search Product" onKeyPress={handlePressEnter} />
              &nbsp;
              &nbsp;
              <Button
                variant="secondary"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
                onClick={() => { setEditProduct(null); setShowModal(true) }} >Add&nbsp;New
              </Button>

            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Store</th>
                <th>Category</th>
                <th>Product ({products.count})</th>
                <th>Addons</th>
                <th>Variants</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(products.list.values()).map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.store.name}</td>
                      <td>{item.category}</td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;

                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditProduct(products.list.get(item._id))}>
                          {item.name}
                        </a> {item.bestSeller ? ' ⭐' : ''}
                      </td>
                      <td>
                        { item.addOns?.length }
                      </td>
                      <td>
                        { item.variants.length ? (
                          item.variants.map(variant => {
                            return <span key={variant.name}>{variant.name}<br/></span>
                          })
                        ) : ''}
                      </td>
                      <td>
                        { !item.variants.length ? (
                          `₱${item.price.toFixed(2)}`
                        ) : (
                          <>
                          {
                            item.variants.map(variant => {
                              return <span key={variant.name}>₱{variant?.price.toFixed(2)}<br/></span>
                            })
                          }
                          </>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant={ item.soldOut ? 'danger' : 'secondary'} id="dropdown-basic" size="sm">
                            { item.soldOut ? 'SOLD OUT' : 'AVAILABLE'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setSoldOut(item._id, true)}>SOLD OUT</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSoldOut(item._id, false)}>AVAILABLE</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          { products.isLoading && (
            <div className="center-hv">
              <Spinner animation="border" variant="primary" />
              <span>&nbsp;&nbsp;Fetching Data..</span>
            </div>
          )}

          { !products.isLoading && !products.list.size && (
            <div className="center-hv">
              <span>No records found!</span>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <Pagination
              page={products.page}
              pages={products.pages}
              onPageClick={onPageClick}
            />
          </div>

        </PortletBody>
      </Portlet>
    </>
  );
}

function ProductDetailModal(props) {
  const { product, stores, store, onSubmit, onHide } = props

  const [categories, setCategories] = useState([])
  const [errorMsg, setErrorMsg] = useState('')

  const [keepModal, setKeepModal] = useState(false)
  const [hasVariant, setHasVariant] = useState(false)

  const [variants, setVariants] = useState([{ name: '', price: '' }])

  const [bestSeller, setBestSeller] = useState(false)
  const [storeId, setStoreId] = useState(store)
  const [category, setCategory] = useState('')
  const [price, setPrice] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [photo, setPhoto] = useState(DEFAULT_PHOTO)
  const [soldOut, setSoldOut] = useState(false)
  const [showVariantName, setShowVariantName] = useState(false)

  const nameRef = useRef(null);

  // -- hook

  useEffect(() => {
    const storeObj = stores.list.get(store || storeId)

    if(storeObj) {
      setCategories(storeObj.menuCategories)
      if (storeObj.menuCategories.length) {
        setCategory(storeObj.menuCategories[0])
      }
    }

    if (store) {
      setStoreId(store)
    }

    setShowVariantName(typeof product?.showVariantName === 'boolean' ? product?.showVariantName : true )
    setBestSeller(product?.bestSeller || false)
    setSoldOut(product?.soldOut || false)
    setName(product?.name || '')
    setDesc(product?.description || '')
    setPrice(product?.price || '')

    if (product?.category) {
      setCategory(product?.category)
    }

    setHasVariant(!!product?.variants.length || false)
    setVariants(product?.variants || [])
    setPhoto(product?.photo || DEFAULT_PHOTO)

  }, [stores, storeId, store, product]);

  // -- user actions

  const handleSubmit = () => {
    if (!storeId) return setErrorMsg('Please select store.')
    if (!name) return setErrorMsg('Please enter product name.')
    if (!desc) return setErrorMsg('Please enter product description.')

    if (hasVariant) {
      for (let i = 0; i < variants.length; i++) {
        if (!variants[i].name || !variants[i].price) {
          return setErrorMsg('Please enter a valid variant name and price.')
        }
      }
    } else {
      if (!price) {
        return setErrorMsg('Please enter a valid product price.')
      }
    }

    setErrorMsg('')

    const data = {
      store: storeId,
      category,
      description: desc,
      name,
      price: +price,
      bestSeller,
      showVariantName,
      soldOut,
      photo,
    }

    if (hasVariant) {
      data.variants = variants
    }

    if (product) {
      onSubmit(data)
    } else {
      onSubmit({
        ...data,
        isActive: true,
      })
    }

    if (keepModal) {
      setName('')
      setDesc('')
      setPrice('')
    } else {
      handleHide()
    }
  }

  const handleHide = () => {
    setPrice('')
    setBestSeller(false)
    setSoldOut(false)
    setShowVariantName(true)
    setStoreId(null)
    setCategories([])
    setCategory('')
    setName('')
    setDesc('')
    
    setKeepModal(false)
    onHide()
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
      nameRef.current.focus()
    }
  }

  const addVariantFields = () => {
    setVariants([...variants, { name: '', price: '' }])
  }

  const rmvVariantField = (i) => {
    variants.splice(i, 1)
    setVariants(variants)
  }

  const updVariantField = (ix, field, value) => {
    for (let i = 0; i < variants.length; i++) {
      if (ix === i) {
        variants[i][field] = field === 'price' ? +value : value
        setVariants([...variants])
        return
      }
    }
  }

  const onChangeHasVariant = (e) => {
    setHasVariant(e.target.checked)

    if(e.target.checked && !variants.length) {
      setVariants([{ name: '', price: '' }])
    }
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="lg" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{ product ? (
          <>
            Edit Product 
            <small> / {product._id}</small>
          </>
        ) : 'Add New Product'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        { !!errorMsg && <code>{errorMsg}</code>}

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>Store</Form.Label>
              <Col sm={9}>
                <SelectSearch
                  value={storeId || props.store}
                  options={props.options} 
                  placeholder="Choose Store" 
                  onChange={setStoreId}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Category</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" onChange={(e) => setCategory(e.target.value)} value={category}>
                  { categories.sort().map(cat => (
                    <option key={cat}>{cat}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={6}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} ref={nameRef} onKeyPress={handlePressEnter}/></Col>
              <Col sm={3}><Button variant="warning" className="btn-sm btn-label-warning" onClick={() => setDesc(`${category}: ${name}`)} >Paste on Description</Button></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Description</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Description" onChange={(e) => setDesc(e.target.value)} value={desc} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            { !hasVariant && (
              <Form.Group as={Row}>
                <Form.Label column sm={3}>Price</Form.Label>
                <Col sm={9}><Form.Control type="number" placeholder="Price" onChange={(e) => setPrice(e.target.value)} value={price} onKeyPress={handlePressEnter}/></Col>
              </Form.Group>
            )}

            { hasVariant && variants.map((variant, i) => {
              return (
                <Form.Group as={Row} key={i}>
                  <Form.Label column sm={3}>Variant {i + 1}</Form.Label>
                  <Col sm={5}><Form.Control type="text" placeholder="Name" onChange={(e) => updVariantField(i, 'name', e.target.value)} value={variant.name}/></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="Price" onChange={(e) => updVariantField(i, 'price', e.target.value)} value={variant.price} onKeyPress={handlePressEnter}/></Col>
                  { i > 0 && (
                    <Col sm={2}>
                      <a href="#" onClick={() => rmvVariantField(i)} className="btn btn-label-danger">Remove</a>
                    </Col>
                  )}
                </Form.Group>
              )
            })}

            { hasVariant && (
              <Form.Group as={Row} >
                <Form.Label column sm={3}></Form.Label>
                <Col sm={5}>
                  <a href="#" onClick={addVariantFields}>Add Variant Field</a>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Photo</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Photo" onChange={(e) => setPhoto(e.target.value)} value={photo} onFocus={(event) => event.target.select()}/></Col>
            </Form.Group>


            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={3}></Form.Label>
                <Col sm={9}>
                  <Row>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-bs" label="Best Seller" onChange={(e) => setBestSeller(e.target.checked)} checked={bestSeller} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-so" label="Sold Out" onChange={(e) => setSoldOut(e.target.checked)} checked={soldOut} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-hb" label="Has Variants" onChange={onChangeHasVariant} checked={hasVariant} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-sb" label="Show VN" onChange={(e) => setShowVariantName(e.target.checked)} checked={showVariantName} />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Form.Check type="checkbox" id="checkbox-keep" label="Keep Modal" onChange={(e) => setKeepModal(e.target.checked)} checked={keepModal} />

        <Button variant="primary" className="btn-sm" type="submit" onClick={handleSubmit} >{product ? 'Save' : 'Submit'}</Button>
        <Button variant="secondary" className="btn-sm" onClick={handleHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
    stores: state.stores,
		products: state.products,
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    _pduck: bindActionCreators(pduck.actions, dispatch),
    _sduck: bindActionCreators(sduck.actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
