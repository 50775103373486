import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/orders.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: [],

  currentId: null
}

const persistConfig = {
  storage,
  key: 'elves-orders',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.orders.page
export const getCurrentId= (state) => state.orders.currentId

// -- action types

export const actionTypes = {
  ORDER_REQ: 'ORDER_REQ',
  ORDER_LOAD: 'ORDER_LOAD',

  ORDERS_REQ: 'ORDERS_REQ',
  ORDERS_LOAD: 'ORDERS_LOAD',

  ORDERS_UPDATE_REQ: 'ORDERS_UPDATE_REQ',
}

// -- actions

export const actions = {
  getOrders: (page) => ({ type: actionTypes.ORDERS_REQ, page }),
  fillOrders: payload => ({ type: actionTypes.ORDERS_LOAD, payload }),

  getOrder: (_id) => ({ type: actionTypes.ORDER_REQ, _id }),
  patchOrder: (_id, data) => ({ type: actionTypes.ORDERS_UPDATE_REQ, payload: { _id, data } }),
  fillOrder: payload => ({ type: actionTypes.ORDER_LOAD, payload }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.ORDER_REQ, function* getOrderSaga() {
    try {
      const _id = yield select(getCurrentId)
      const { data: response } = yield crud.read(_id)

      yield put(actions.fillOrder(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });

  yield takeLatest(actionTypes.ORDERS_REQ, function* getOrdersSaga() {
    try {
      const page = yield select(getCurrentPage)
      const { data: response } = yield crud.readMany(page)

      yield put(actions.fillOrders(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });

  yield takeLatest(actionTypes.ORDERS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillOrder(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDER_REQ: {
      return { ...state, currentId: action._id }
    }

    case actionTypes.ORDERS_REQ: {
      return { ...state, page: action.page }
    }

    case actionTypes.ORDERS_LOAD: {
      const { page, pages, count, data: list } = action.payload;
      return { ...state, count, page, pages, list };
    }

    case actionTypes.ORDER_LOAD: {
      const { _id } = action.payload
      const { list } = state

      let found = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          found = true
          break
        }
      }

      if (!found) {
        list.push(action.payload)
      }

      return { ...state }
    }

    default:
      return state;
  }
});