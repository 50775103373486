/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../partials/content/Portlet';

import {
  Col,
  Row,
  Form,
  Toast,
  Modal,
  Table,
  Button,
  Dropdown,
  Container,
} from 'react-bootstrap';

import * as duck from '../../store/ducks/riders.duck'
import Pagination from '../../partials/component/Pagination';

const DEFAULT_PHOTO = 'https://res.cloudinary.com/higher-elves/image/upload/v1623124729/profile/profile_domcmz.png'

function Riders(props) {
  const { getRiders, riders, updateUser, toastClear } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  
  const [showRiderModal, setShowRiderModal] = useState(false);
  const [riderObj, setRiderObj] = useState(null);

  // -- hooks

  useEffect(() => {
    getRiders()
  }, [getRiders])

  // -- user action

  const updateUserStatus = (_id, status) => {
    updateUser(_id, {
      isActive: true,
      status,
    })
  }

  const updateRiderStatus = (_id, riderStatus) => {
    updateUser(_id, { riderStatus })
  }

  const initiateBanning = (_id) => {
    setModalVisible(true)
    setSelectedUser(_id)
  }

  const banUser = (banReason) => {
    if (!selectedUser) return

    updateUser(selectedUser, {
      status: 'BANNED',
      isActive: false,
      banReason
    })

    setModalVisible(false)
  }

  const onEditRider = (rider) => {
    setRiderObj(rider)
    setShowRiderModal(true)
  }

  const handleModalSubmit = (data) => {
    if (riderObj) {
      updateUser(riderObj._id, data)
    }
  }

  // -- render

  return (
    <>
      <BanReasonModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        onSubmit={reason => banUser(reason)}
      />

      <RiderModal
        show={showRiderModal}
        rider={riderObj}
        onHide={(cb) => setShowRiderModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Riders{' '}
              <small>Global rider manager.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              {/* <a href='/#' className='btn btn-label-success btn-bold btn-sm btn-icon-h' >
                Add New
              </a> */}
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>
                <th>isActive</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                riders.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditRider(item)}>
                          {item.fbun ? item.fbun : item._id.substr(0, 6)}
                        </a>
                      </td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;
                        {item.name}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>
                        {
                          item.riderStatus === 'ONLINE'
                            ? <span className="kt-font-success">{item.riderStatus}</span>
                            : <span className="kt-font-danger">{item.riderStatus || 'OFFLINE' }</span>
                        }
                      </td>
                      <td>
                        {
                          item.isActive
                            ? <span className="kt-font-success">Active</span>
                            : <span className="kt-font-danger">Not Active</span>
                        }
                      </td>
                      <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                          Action
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {
                            item.status === 'VERIFIED' ? (
                              <Dropdown.Item onClick={() => initiateBanning(item._id)}>Ban User</Dropdown.Item>
                            ) : (
                              <>
                                <Dropdown.Item onClick={() => updateUserStatus(item._id, 'VERIFIED')}>Verify</Dropdown.Item>
                                <Dropdown.Item onClick={() => updateUserStatus(item._id, 'UNREACHABLE')}>Unreachable</Dropdown.Item>
                              </>
                            )
                          }
                          {
                            item.riderStatus === 'ONLINE' ? (
                              <Dropdown.Item onClick={() => updateRiderStatus(item._id, 'OFFLINE')}>Set as Offline</Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => updateRiderStatus(item._id, 'ONLINE')}>Set as Online</Dropdown.Item>
                            )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={riders.page}
              pages={riders.pages}
              onPageClick={getRiders}
            />
          </div>

        </PortletBody>
      </Portlet>

      <div style={{ position: 'absolute', top: -20, right: 45 }}>
        <Toast onClose={() => toastClear()} show={!!riders.toastErr} delay={1000 * 6} autohide>
          <Toast.Header>
            <i className="flaticon-warning kt-font-danger"></i>
            <strong className="mr-auto">&nbsp;&nbsp;{riders.toastErr?.error}</strong>
          </Toast.Header>
          <Toast.Body>{riders.toastErr?.message}</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

function BanReasonModal(props) {
  const [reason, setReason] = useState('')

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Enter Banning Reason</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>Reason</Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder="Reason.."
                  onChange={e => setReason(e.target.value)}
                />
              </Col>

            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit(reason)} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function RiderModal (props) {
  const { rider } = props

  const [name, setName] = useState('')
  const [fbun, setFbun] = useState('')
  const [photo, setPhoto] = useState(DEFAULT_PHOTO)

  useEffect(() => {
    if (rider) {
      setName(rider.name)
      setFbun(rider.fbun || '')
      setPhoto(rider.photo || DEFAULT_PHOTO)
    }
  }, [rider]);

  const handleSubmit = () => {
    const capturedValues = {
      fbun, photo
    }

    if (rider) {
      props.onSubmit(capturedValues)
    }

    handleHide()
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleHide = () => {
    setName('')
    props.onHide()
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Rider</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              {/* <Col sm={4}> */}
                <div className="kt-widget5__pic">
                  <img alt="" className="kt-widget7__img order-detail-image" src={rider?.photo} />
                </div>
              {/* </Col> */}
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} disabled/></Col>
            </Form.Group>

            

            <Form.Group as={Row}>
              <Form.Label column sm={3}>FB Username</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Facebook Username" onChange={(e) => setFbun(e.target.value)} value={fbun} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Photo</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Photo" onChange={(e) => setPhoto(e.target.value)} value={photo} onFocus={(event) => event.target.select()}/></Col>
            </Form.Group>

          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ rider ? 'Save' : 'Submit'}</Button>
        <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		riders: state.riders,
	}
}

export default connect(mapStateToProps, duck.actions)(Riders);
