import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from '../../../partials/content/Portlet';

import {
  Table, Button,
  Dropdown, ButtonToolbar, DropdownButton,
  Modal, Form, Col, Row, Container,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import RiderSelectModal from './RiderSelectModal';
import * as duck from '../../../store/ducks/orders.duck'

const STATUSES = [
  'PENDING',
  'CONFIRMED',
  'PROCESSING',
  'READY',
  'SERVED',
  'CANCELLED',
  'UNCLAIMED'
]

const STATUS_COLORS = {
  PENDING: 'secondary',
  CONFIRMED: 'primary',
  PROCESSING: 'warning',
  READY: 'success',
  SERVED: 'secondary',
  CANCELLED: 'danger',
  UNCLAIMED: 'danger'
}

const MARKUP = 0.1

function Orders(props) {
  const { id } = useParams();
  const { orders, getOrder, patchOrder } = props;
  
  let order = orders.list.find(e => e._id === id);

  const [timeLapse, setTimeLapse] = useState('0h 0m 0s');
  const [modalVisible, setModalVisible] = useState(false);
  const isFulfilled = order && ['CANCELLED', 'UNCLAIMED', 'SERVED'].includes(order.status);

  const [cancelReasonVisible, setCancelReasonVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // -- hooks

  useEffect(() => {
    if (!order) {
      getOrder(id)
    } else {

      const calcTimeLapse = () => {
        const startDate = new Date(order.createdAt);
        const endDate   = isFulfilled ? new Date(order.dateFulfilled) : new Date();

        const timestamp = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);

        const days = Math.floor(timestamp / 60 / 60 / 24);
        const hours = Math.floor(timestamp / 60 / 60);
        const minutes = Math.floor(timestamp / 60) - (hours * 60);
        const seconds = timestamp % 60;

        let strLapse = `${hours - (days * 24)}h ${minutes}m ${seconds}s`;

        strLapse = days > 0 ? `${days}d ${strLapse}` : strLapse;

        setTimeLapse(strLapse);
      };

      if (isFulfilled) {
        calcTimeLapse();
      } else {
        const interval = setInterval(() => { calcTimeLapse(); }, 1000);
        return () => { clearInterval(interval); };
      }
    }
  }, [getOrder, id, order, isFulfilled])

  // -- user actions

  const updateOrderStatus = (_id, status) => {
    if (status === 'CANCELLED') {
      setCancelReasonVisible(true)
      setSelectedOrder(_id)
    } else {
      patchOrder(_id, { status })
    }
  }

  const assignRider = (rider) => {
    patchOrder(order._id, { rider })

    if (order.groupId) {
      for (let i = 0; i < orders.list.length; i++) {
        const item = orders.list[i];
        
        if (item.groupId === order.groupId && order._id !== item._id) {
          patchOrder(item._id, { rider })
        }
      }
    }

    setModalVisible(false)
  }

  // -- render

  if (!order) {
    return (<></>);
  }

  return (
    <>
      <RiderSelectModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        assignRider={assignRider}
      />

      <CancelReasonModal
        show={cancelReasonVisible}
        onHide={() => setCancelReasonVisible(false)}
        onSubmit={reason => {
          patchOrder(selectedOrder, {
            status: 'CANCELLED',
            cancelReason: `${reason}\n**Cancelled by Admin**`
          })

          setCancelReasonVisible(false)
        }}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              <Link to="/orders">Orders</Link>
              <small>/ {id} / { order.orderStub }</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <div className="kt-widget4">
                <div className="kt-widget4__item ">
                  {
                    order.rider ? (
                      <>
                        <div className="kt-widget4__pic kt-widget4__pic--pic ">
                          <img alt="" src={order.rider.photo} className="rider-img-circle"/>
                        </div>
                        <div className="kt-widget4__info ">
                          <a className="kt-widget4__username" href="#/" >Rider: {order.rider.name}</a>
                          <p className="kt-widget4__text ">{order.rider.phone}</p>
                        </div>
                        { !isFulfilled && <a href="#/" className="btn btn-sm btn-label-success" onClick={() => setModalVisible(true)}>Re-assign</a> }
                        
                      </>
                    ) : (
                      <a href="#/" className="btn btn-sm btn-label-success" onClick={() => setModalVisible(true)}>Assign Rider</a>
                    )
                  }
                </div>
              </div>

              &nbsp;&nbsp;
              {/* Status:&nbsp;&nbsp; */}

              <ButtonToolbar>
                  <DropdownButton
                    size="sm"
                    variant={STATUS_COLORS[order.status]}
                    title={order.status}
                    key={123}
                  >
                    {
                      STATUSES.map(status => {
                        if (status !== order.status) {
                          return <Dropdown.Item key={`${order._id}-${status}`} onClick={() => updateOrderStatus(order._id, status)}>{status}</Dropdown.Item>
                        } else {
                          return undefined;
                        }
                      })
                    }
                  </DropdownButton>
              </ButtonToolbar>
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>

          {/* <div className="kt-separator kt-separator--dashed"></div> */}
          
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-6">

              {/* STORE INFO */}

              <div className="kt-widget5">
                <div className="kt-widget5__item mb-0 pb-0">
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__pic">
                    <img alt="" className="kt-widget7__img order-detail-image" src={order.store.photo} />
                    </div>
                    <div className="kt-widget5__section">
                      <Link to={`/customers/${order.owner._id}`} className="kt-widget5__title">
                        {order.store.name}
                      </Link>

                      <p className="kt-widget5__desc">{order.store.description}</p>
                      <div className="kt-widget5__info">
                        <span> {order.store.address}</span>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__stats">
                      <span className="kt-widget5__number">Phones</span>
                      <span className="kt-widget5__sales">{`G: ${order.store.phoneGlobe.substring(0, 4)} ${order.store.phoneGlobe.substring(4, 7)} ${order.store.phoneGlobe.substring(7, order.store.phoneGlobe.length)}`}</span>
                      <span className="kt-widget5__votes">{`S: ${order.store.phoneSmart.substring(0, 4)} ${order.store.phoneSmart.substring(4, 7)} ${order.store.phoneSmart.substring(7, order.store.phoneSmart.length)}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">

              {/* CUSTOMER INFO */}

              <div className="kt-widget5">
                <div className="kt-widget5__item mb-0 pb-0">
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__pic">
                      <img alt="" className="kt-widget7__img order-detail-image" src={order.owner.photo} />
                    </div>
                    <div className="kt-widget5__section">
                      <Link to={`/customers/${order.owner._id}`} className="kt-widget5__title">
                        ({order.owner.txnCount}) {order.owner.name}
                      </Link>

                      <p className="kt-widget5__desc">{order.owner.address}</p>
                      <div className="kt-widget5__info">
                        <code>Near: {order.marker.name}</code>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__stats">
                      <span className="kt-widget5__number">Phone</span>
                      <span className="kt-widget5__sales">{`${order.owner.phone.substring(0, 4)} ${order.owner.phone.substring(4, 7)} ${order.owner.phone.substring(7, order.owner.phone.length)}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />

          {/*  -- ORDERED ITEMS -- */}

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ordered Items ({order.items.length})</th>
                <th>Product</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                order && order.items.map(({ product, quantity, variant, addons }, index) => {
                  let productPrice = product.variants.length
                    ? product.variants[variant].price
                    : product.price

                  productPrice = productPrice + (productPrice * MARKUP)

                  let subTotal = productPrice
                  const pUnavailable = order.xProducts.includes(product._id);

                  return (
                    <tr key={product._id}>
                      <td>
                        <Link to={`/product/${product._id}`} className="kt-footer__menu-link kt-link">
                          {product._id}
                        </Link>
                      </td>
                      <td>
                        <span className={pUnavailable ? 'item-crossed' : ''}>
                          {product.name}{product.variants.length ? ` (${product.variants[variant].name})` : ''}
                        </span>
                        {
                          addons.map(addonId => {
                            const addon = product.addOns.find(e => e._id === addonId);
                            
                            return <span className={order.xAddons.includes(addonId) || pUnavailable ? 'item-crossed' : ''} key={addonId}>
                              <br/>+ {addon.name}
                            </span>
                          })
                        }
                      </td>
                      <td>
                        <span className={pUnavailable ? 'item-crossed' : ''}>
                          ₱{productPrice.toFixed(2)}
                        </span>
                        
                        {
                          addons.map(addonId => {
                            const addon = product.addOns.find(e => e._id === addonId);
                            const addonPrice = addon.price + (addon.price * MARKUP)

                            return <span className={order.xAddons.includes(addonId) || pUnavailable? 'item-crossed' : ''} key={addonId}>
                              <br/>₱<span>{(addonPrice).toFixed(2)}</span>
                            </span>
                          })
                        }
                      </td>

                      <td>
                        <span className={pUnavailable ? 'item-crossed' : ''}>
                          x{quantity}
                        </span>
                        {
                          addons.map(addonId => {
                            return <span className={order.xAddons.includes(addonId) || pUnavailable? 'item-crossed' : ''} key={addonId}>
                              <br/>x{quantity}
                            </span>
                          })
                        }
                      </td>

                      <td>
                        <span className={pUnavailable ? 'item-crossed' : ''}>
                          { addons.length ? (
                            `₱${(productPrice * quantity).toFixed(2)}`
                          ) : (
                            <span className="order-detail-subtotal">₱{(productPrice * quantity).toFixed(2)}</span>
                          )}
                        </span>
                        
                        {
                          addons.map(addonId => {
                            const addon = product.addOns.find(e => e._id === addonId);
                            const addonPrice = addon.price + (addon.price * MARKUP)
                            const aUnavailable = order.xAddons.includes(addonId)

                            if (!aUnavailable || pUnavailable) {
                              subTotal += addonPrice
                            }

                            return <span className={aUnavailable || pUnavailable ? 'item-crossed' : ''} key={addonId}>
                              <br/>₱<span>{(addonPrice * quantity).toFixed(2)}</span>
                            </span>
                          })
                        }
                        <span className={pUnavailable ? 'item-crossed' : ''}>
                          { !!addons.length && <><br/><span className="order-detail-subtotal">₱{(subTotal * quantity).toFixed(2)}</span></> }
                        </span>
                        {/* { !!addons.length && <><br/><span className="order-detail-subtotal">₱{(subTotal * quantity).toFixed(2)}</span></> } */}
                      </td>
                      {/* <td>₱ {(quantity * subTotal).toFixed(2)}</td> */}
                    </tr>
                  )
                })
              }
              {/* <tr>
                <td></td>
                <td><i>**Delivery Fee**</i></td>
                <td></td>
                <td></td>
                <td>₱ {(order.deliveryFee).toFixed(2)}</td>
              </tr> */}
              {/* <tr>
                <td></td>
                <td><i>**Total**</i></td>
                <td></td>
                <td></td>
                <td>
                  <h4><code>₱ {(order.totalPrice).toFixed(2)}</code></h4>
                </td>
              </tr> */}

            </tbody>
          </Table>

          {/* <br /> */}

          <div className="row row-no-padding">
            <div className="col-xl-4">
              <div className="kt-widget1">

                <h2 className="kt-font-danger">
                  Timer: {timeLapse}
                </h2>
              </div>
            </div>

            <div className="col-xl-4">

            </div>

            <div className="col-xl-4">

              {/* GRAND TOTAL */}

              <div className="kt-widget1">
                <div className="kt-widget1__item ng-star-inserted">
                  <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Grand Total</h3>
                    <span className="kt-widget1__desc"><i>Deliver Fee (₱ {(order.deliveryFee).toFixed(2)})</i></span>
                  </div>

                  <span className="kt-widget1__number kt-font-danger">₱ {(order.deliveryFee + order.totalPrice).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

        </PortletBody>
      </Portlet>
    </>
  );
}

function CancelReasonModal(props) {
  const [reason, setReason] = useState('')

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cancel Reason</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>Reason</Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder="Enter Reason.."
                  onChange={e => setReason(e.target.value)}
                />
              </Col>

            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit(reason)} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		orders: state.orders,
	}
}

export default connect(mapStateToProps, duck.actions)(Orders);
