import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import * as crud from '../../crud/_shops.cruds'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),
  products: new Map(),

  currentId: null,
  isLoading: true,

  changedOwner: '',

  // -- lookup

  lookup: new Map(),
}

const persistConfig = {
  storage,
  key: 'elves-shops',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._shops.page
export const getCurrentId = (state) => state._shops.currentId

// -- action types

export const actionTypes = {
  SHOP_ADD: 'SHOP_ADD',

  SHOP_REQ: 'SHOP_REQ',
  SHOP_LOAD: 'SHOP_LOAD',

  SHOPS_REQ: 'SHOPS_REQ',
  SHOPS_LOAD: 'SHOPS_LOAD',
  
  SHOPS_QUERY_REQ: 'SHOPS_QUERY_REQ',
  SHOPS_SEARCH_REQ: 'SHOPS_SEARCH_REQ',
  SHOPS_UPDATE_REQ: 'SHOPS_UPDATE_REQ',

  SHOPS_DELETE_REQ: 'SHOPS_DELETE_REQ',
  SHOPS_DELETE_APPLY: 'SHOPS_DELETE_APPLY',

  SHOPS_LKUP: 'SHOPS_LKUP',
  SHOPS_LKUP_DONE: 'SHOPS_LKUP_DONE',
  SHOPS_LKUP_LOAD: 'SHOPS_LKUP_LOAD',

  SHOP_CHANGE_OWNER: 'SHOP_CHANGE_OWNER',
}

// -- actions

export const actions = {
  addRecord: payload => ({ type: actionTypes.SHOP_ADD, payload }),

  getRecords: (page) => ({ type: actionTypes.SHOPS_REQ, page }),
  fillRecords: response => ({ type: actionTypes.SHOPS_LOAD, payload: { response } }),

  doSearch: text => ({ type: actionTypes.SHOPS_SEARCH_REQ, text }),
  doQuery: query => ({ type: actionTypes.SHOPS_QUERY_REQ, query }),

  getRecord: _id => ({ type: actionTypes.SHOP_REQ, _id }),
  patchRecord: (_id, data) => ({ type: actionTypes.SHOPS_UPDATE_REQ, payload: { _id, data } }),
  fillRecord: payload => ({ type: actionTypes.SHOP_LOAD, payload }),

  delRecord: _id => ({ type: actionTypes.SHOPS_DELETE_REQ, _id }),
  fillDelete: _id => ({ type: actionTypes.SHOPS_DELETE_APPLY, _id }),

  lookup: (query) => ({ type: actionTypes.SHOPS_LKUP, query }),
  fillLookups: records => ({ type: actionTypes.SHOPS_LKUP_LOAD, records }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.SHOPS_REQ, function* getSaga() {
    try {
      const page = yield select(getCurrentPage);

      const { data: response } = yield crud.getMany(page);
      yield put(actions.fillRecords(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeEvery(actionTypes.SHOPS_LKUP, function* lookupSaga(action) {
    try {

      const { data: records } = yield crud.lookup(action.query);
      
      yield put(actions.fillLookups(records));
      yield put({ type: actionTypes.SHOPS_LKUP_DONE })
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOPS_QUERY_REQ, function* getActivesSaga(action) {
    try {
      const { data: response } = yield crud.query(action.query)
      yield put(actions.fillRecords(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOPS_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillRecords(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOPS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillRecord(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOP_CHANGE_OWNER, function* patchOwnerSaga(action) {
    const { _id, data } = action.payload

    try {
      yield crud.changeOwner(_id, data)
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOP_REQ, function* getRecordSaga() {
    try {
      const _id = yield select(getCurrentId)
      const { data: response } = yield crud.read(_id)

      yield put(actions.fillRecord(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOPS_DELETE_REQ, function* delRecordSaga() {
    try {
      const _id = yield select(getCurrentId)
      yield crud.del(_id)

      yield put(actions.fillDelete(_id))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.SHOP_ADD, function* postSaga(action) {
    try {
      const { data: response } = yield crud.post(action.payload)
      yield put(actions.fillRecord(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {

    // --

    case actionTypes.SHOPS_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.page
      };
    }

    case actionTypes.SHOPS_LOAD: {
      const { page, pages, count, data } = action.payload.response;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    // -- 

    case actionTypes.SHOP_REQ:{
      return {
        ...state,
        isLoading: true,
        currentId: action._id
      }
    }

    case actionTypes.SHOP_LOAD: {
      const { _id } = action.payload
      const { list } = state

      list.set(_id, action.payload)

      return { ...state, list, isLoading: false, }
    }

    // --

    case actionTypes.SHOPS_DELETE_REQ:{
      return {
        ...state,
        isLoading: true,
        currentId: action._id
      }
    }

    case actionTypes.SHOPS_DELETE_APPLY:{
      const { list } = state

      list.delete(action._id)

      return {
        ...state, list,
        isLoading: false,
        currentId: null
      }
    }

    // --

    case actionTypes.SHOPS_LKUP_LOAD: {
      const { lookup } = state

      action.records.forEach(record => {
        lookup.set(record._id, record)
      });

      return { ...state, lookup }
    }


    default:
      return state;
  }
});
