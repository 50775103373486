import React, { useEffect } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import * as duck from '../../../store/ducks/riders.duck'

function RiderSelectModal (props) {
  const {
    show, onHide, assignRider,
    getRiders, riders,
  } = props;

  // -- hooks

  useEffect(() => {
    getRiders('ONLINE')
  }, [getRiders])

  // -- render

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Online Riders</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            {
              riders.list.map((rider, index) => {
                if (rider.riderStatus === 'ONLINE') {
                  return (
                    <div className="kt-widget4__item " key={rider._id}>
                      <div className="kt-widget4__pic kt-widget4__pic--pic ">
                        <img alt="" src={rider.photo} className="rider-img-circle"/>
                      </div>
                      <div className="kt-widget4__info ">
                        <a className="kt-widget4__username" href="#/" >{rider.name}</a>
                        <p className="kt-widget4__text ">{rider.phone}</p>
                      </div>
                      <a href="#/" className="btn btn-sm btn-success" onClick={() => assignRider(rider._id)}>Select</a>
                    </div>
                  )
                } else {
                  return <></>
                }
              })
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		riders: state.riders,
	}
}

export default connect(mapStateToProps, duck.actions)(RiderSelectModal);
