import axios from 'axios';
import { API_SERVER } from '../../config'

export const API_RESOURCE = `${API_SERVER}/towns`

const LIMIT = 50

export function getMany(page, limit) {
  let queryString = ''

  if (page) queryString += `&page=${page}`

  return axios.get(`${API_RESOURCE}?limit=${limit || LIMIT}${queryString}`);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${12}`);
}

export function query(query = {}, page = 1) {
  const { limit, sort } = query

  let queryString = ''

  if (page) queryString += `&page=${page}`
  if (sort) queryString += `&sort=${sort}`

  return axios.get(`${API_RESOURCE}?limit=${limit || LIMIT}${queryString}&active=true`);
}