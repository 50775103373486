/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';


import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../partials/content/Portlet';

import {
  Toast, Table, Dropdown,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as duck from '../../store/ducks/_expenses.duck'
import Pagination from '../../partials/component/Pagination';

function Expenses (props) {
  const { id: riderId, name } = useParams()
  const { getRecords, expenses, toastClear, patchRecord } = props;
  const currentPage = expenses.page || 1;

  // -- hooks

  useEffect(() => {
    getRecords(currentPage, riderId)
  }, [getRecords, currentPage, riderId])

  // -- actions

  // -- render

  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            <>
              <Link to="/_riders">Riders </Link>
              / <code>{name}</code>&nbsp;
              <small>{riderId}</small>
            </>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>fbun ({expenses.count})</th>
                <th>Date</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Acknowledged</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                expenses.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item._id.substr(0, 6)}</td>
                      <td>{item.createdAt}</td>
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>
                        {
                          item.ack
                            ? <span className="kt-font-success">TRUE</span>
                            : <span className="kt-font-danger">FALSE</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {
                              <Dropdown.Item onClick={() => patchRecord(item._id, { ack: !item.ack })}>{item.ack ? 'UNACK' : 'ACKNOWLEDGE'}</Dropdown.Item>
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={expenses.page}
              pages={expenses.pages}
              onPageClick={getRecords}
            />
          </div>
        
        </PortletBody>
      </Portlet>

      <div style={{ position: 'absolute', top: -20, right: 45 }}>
        <Toast onClose={() => toastClear()} show={!!expenses.toastErr} delay={1000 * 6} autohide>
          <Toast.Header>
            <i className="flaticon-warning kt-font-danger"></i>
            <strong className="mr-auto">&nbsp;&nbsp;{expenses.toastErr?.error}</strong>
          </Toast.Header>
          <Toast.Body>{expenses.toastErr?.message}</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
		expenses: state._expenses,
	}
}

export default connect(mapStateToProps, duck.actions)(Expenses);
