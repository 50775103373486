import 'firebase/auth'

import axios from "axios";
import firebase from "firebase/app";

import { API_SERVER } from '../../config'

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

// -- from templates, keeping it for future use

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

// -- local elves

export function firebaseLogin(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

export function firebaseLogout() {
  return firebase.auth().signOut()
}

export function getSelf() {
  return axios.get(`${API_SERVER}/users/self`);
}