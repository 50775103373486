import axios from 'axios';
import { API_SERVER } from '../../config'

export const API_RESOURCE = `${API_SERVER}/users`

const LIMIT = 10

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?role=customer&page=${page}&limit=${LIMIT}`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}`);
}