/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';

import {
  Col, Row,
  Form, Modal,
  Button, Container,
} from "react-bootstrap";

import { TEMP_PHOTO } from '../../../../config';

function ProductModal (props) {
  const { shop, product, onHide, onSubmit } = props

  const [errorMsg, setErrorMsg] = useState('')

  const [keepModal, setKeepModal] = useState(false)
  const [hasVariant, setHasVariant] = useState(false)

  const [variants, setVariants] = useState([{ name: '', price: '', markup: 0 }])

  const [bestSeller, setBestSeller] = useState(false)
  const [category, setCategory] = useState(shop?.categories[0] || '')
  const [markup, setMarkup] = useState(0)
  const [price, setPrice] = useState(0)
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [tags, setTags] = useState('')
  const [score, setScore] = useState(0)
  const [photo, setPhoto] = useState(TEMP_PHOTO)
  const [soldOut, setSoldOut] = useState(false)
  const [showVariantName, setShowVariantName] = useState(false)

  const nameRef = useRef(null);


  // -- hook

  useEffect(() => {
    setShowVariantName(typeof product?.showVariantName === 'boolean' ? product?.showVariantName : true )
    setBestSeller(product?.hotItem || false)
    setSoldOut(product?.soldOut || false)
    setName(product?.name || '')
    setDesc(product?.description || '')
    setTags(product?.tags || '')
    setScore(product?.score || 0)
    setPrice(isNaN(product?.price) ? '' : product?.price)
    setMarkup(isNaN(product?.markup) ? 0 : product?.markup)

    if (product?.category) {
      setCategory(product?.category)
    }

    setHasVariant(!!product?.variants.length || false)
    setVariants(product?.variants || [])
    setPhoto(product?.photos[0] || TEMP_PHOTO)

  }, [product]);

  // -- user actions

  const handleSubmit = () => {
    if (!name) return setErrorMsg('Please enter product name.')
    if (!desc) return setErrorMsg('Please enter product description.')

    if (hasVariant) {
      for (let i = 0; i < variants.length; i++) {
        if (!variants[i].name || !variants[i].price) {
          return setErrorMsg('Please enter a valid variant name and price.')
        }
      }
    } else if (isNaN(price)) {
      return setErrorMsg('Please enter a valid product price.')
    }

    setErrorMsg('')

    const data = {
			name,
			description: desc,
      tags, score,
			price: +price,
			photos: [photo],
			category,
			soldOut,
      markup: +markup,
			shop: shop?._id,
			showVariantName,
			hotItem: bestSeller,
    }

    if (hasVariant) {
      data.variants = variants
    }

    if (product) {
      onSubmit(data)
    } else {
      onSubmit({ ...data, active: true })
    }

    if (keepModal) {
      setName('')
      setDesc('')
      setPrice('')
      setMarkup(0)
    } else {
      handleHide()
    }
  }

  const handleHide = () => {
    setPrice('')
    setMarkup(0)
    setBestSeller(false)
    setSoldOut(false)
    setShowVariantName(true)
    setCategory(shop?.categories[0] || '')
    setName('')
    setDesc('')

    setKeepModal(false)
    onHide()
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
      nameRef.current.focus()
    }
  }

  const addVariantFields = () => {
    setVariants([...variants, { name: '', price: '', markup: 0 }])
  }

  const rmvVariantField = (i) => {
    variants.splice(i, 1)
    setVariants(variants)
  }

  const updVariantField = (ix, field, value) => {
    for (let i = 0; i < variants.length; i++) {
      if (ix === i) {
        variants[i][field] = field === 'price' || field === 'markup' ? +value : value
        setVariants([...variants])
        return
      }
    }
  }

  const markupVariants = () => {
    let newVariants = []

    for (let i = 0; i < variants.length; i++) {
      const { price } = variants[i]

      newVariants.push({ ...variants[i] })
      newVariants[i].markup = (price * 0.03).toFixed(2)
    }

    setVariants([...newVariants])
  }

  const onChangeHasVariant = (e) => {
    setHasVariant(e.target.checked)

    if(e.target.checked && !variants.length) {
      setVariants([{ name: '', price: '', markup: 0 }])
    }
  }

	const onPasteDesc = () => {
		if (!category && shop?.categories.length) {
			setCategory(shop?.categories[0])
			setDesc(`${shop?.categories[0]}: ${name}`)
		} else {
			setDesc(`${category}: ${name}`)
		}
	}

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="lg" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{ product ? (
          <>
            Edit Product 
            <small> / {product._id}</small>
          </>
        ) : 'Add New Product'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        { !!errorMsg && <code>{errorMsg}</code>}

        <Container className="mt-3">
          <Form>
						<Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={9}><Form.Control type="text" disabled value={shop?.name} /></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Category</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" onChange={(e) => setCategory(e.target.value)} value={category}>
                  { (shop?.categories || []).sort().map(cat => (
                    <option key={cat}>{cat}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Name</Form.Label>
              <Col sm={6}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} ref={nameRef} onKeyPress={handlePressEnter}/></Col>
              <Col sm={3}><Button variant="warning" className="btn-sm btn-label-warning" onClick={onPasteDesc} >Paste in Description</Button></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Description</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Description" onChange={(e) => setDesc(e.target.value)} value={desc} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            { !hasVariant && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Price</Form.Label>
                  <Col sm={9}><Form.Control type="number" placeholder="Price" onChange={(e) => setPrice(e.target.value)} value={price} onKeyPress={handlePressEnter}/></Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Markup</Form.Label>
                  <Col sm={6}><Form.Control type="number" placeholder="Markup" onChange={(e) => setMarkup(e.target.value)} value={markup} onKeyPress={handlePressEnter}/></Col>
                  <Col sm={3}>
                    <Button variant="warning" className="btn-sm btn-label-warning" onClick={() => setMarkup((price * 0.02).toFixed(2))} >Mark 2%</Button>&nbsp;
                    <Button variant="warning" className="btn-sm btn-label-warning" onClick={() => setMarkup((price * 0.03).toFixed(2))} >Mark 3%</Button>
                  </Col>
                </Form.Group>
              </>
            )}

            { hasVariant && variants.map((variant, i) => {
              return (
                <Form.Group as={Row} key={i}>
                  <Form.Label column sm={3}>Variant {i + 1}</Form.Label>
                  <Col sm={3}><Form.Control type="text" placeholder="Name" onChange={(e) => updVariantField(i, 'name', e.target.value)} value={variant.name}/></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="Price" onChange={(e) => updVariantField(i, 'price', e.target.value)} value={variant.price} onKeyPress={handlePressEnter}/></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="Markup" onChange={(e) => updVariantField(i, 'markup', e.target.value)} value={variant.markup} onKeyPress={handlePressEnter}/></Col>
                  { i > 0 && (
                    <Col sm={2}><a href="#" onClick={() => rmvVariantField(i)} className="btn btn-label-danger">Remove</a></Col>
                  )}

                  { i === 0 && (
                    <Col sm={2}><a href="#" onClick={() => markupVariants()} className="btn btn-label-warning">Mark 3%</a></Col>
                  )}
                </Form.Group>
              )
            })}

            { hasVariant && (
              <Form.Group as={Row} >
                <Form.Label column sm={3}></Form.Label>
                <Col sm={5}>
                  <a href="#" onClick={addVariantFields}>Add Variant Field</a>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Tags</Form.Label>
              <Col sm={6}><Form.Control type="text" placeholder="Tags" onChange={(e) => setTags(e.target.value)} value={tags} onKeyPress={handlePressEnter}/></Col>
              <Col sm={3}><Form.Control type="text" placeholder="Score" onChange={(e) => setScore(e.target.value)} value={score} onKeyPress={handlePressEnter}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Photo</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Photo" onChange={(e) => setPhoto(e.target.value)} value={photo} onFocus={(event) => event.target.select()}/></Col>
            </Form.Group>


            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={3}></Form.Label>
                <Col sm={9}>
                  <Row>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-bs" label="Hot Item" onChange={(e) => setBestSeller(e.target.checked)} checked={bestSeller} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-so" label="Sold Out" onChange={(e) => setSoldOut(e.target.checked)} checked={soldOut} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-hb" label="Has Variants" onChange={onChangeHasVariant} checked={hasVariant} />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-sb" label="Show VN" onChange={(e) => setShowVariantName(e.target.checked)} checked={showVariantName} />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Form.Check type="checkbox" id="checkbox-keep" label="Keep Modal" onChange={(e) => setKeepModal(e.target.checked)} checked={keepModal} />

        <Button variant="primary" className="btn-sm" type="submit" onClick={handleSubmit} >{product ? 'Save' : 'Submit'}</Button>
        <Button variant="secondary" className="btn-sm" onClick={handleHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductModal;
