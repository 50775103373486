import axios from 'axios';
import { API_SERVER } from '../../config'

export const API_RESOURCE = `${API_SERVER}/markers`

const LIMIT = 250

export function getMany(page, limit) {
  let queryString = ''

  if (page) queryString += `&page=${page}`

  return axios.get(`${API_RESOURCE}?limit=${limit || LIMIT}${queryString}&isActive=true`);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${12}`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function query(query) {
  const { page, limit, sort } = query

  let queryString = ''

  if (page) queryString += `&page=${page}`
  if (sort) queryString += `&sort=${sort}`

  return axios.get(`${API_RESOURCE}?limit=${limit || LIMIT}${queryString}`);
}