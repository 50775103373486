import axios from 'axios';
import { API_SERVER } from '../../config'

const LIMIT = 12
const EXPAND = ''
const SORT = 'shop category name'

export const API_RESOURCE = `${API_SERVER}/products`

// export function getMany(page = 1) {
//   return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&expand=${EXPAND}`);
// }

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function post(data) {
  let queryString = ''
  if (EXPAND) queryString += `expand=${EXPAND}`
  return axios.post(`${API_RESOURCE}?${queryString}`, data);
}

// export function search(text) {
//   return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&expand=${EXPAND}`);
// }

// export function getByStore(storeId, limit) {
//   return axios.get(`${API_RESOURCE}?store=${storeId}&limit=${limit || LIMIT}&expand=${EXPAND}&sort=${SORT}`);
// }

export function query(query) {
  const { shop, soldOut, search, category, page, expand } = query

  let queryString = ''

  if (shop) queryString += `&shop=${shop}`
  if (soldOut) queryString += `&soldOut=${soldOut}`
  if (search) queryString += `&search=${search}&regex=true`
  if (category) queryString += `&category=${category}`

  return axios.get(`${API_RESOURCE}?page=${page || 1}&limit=${LIMIT}&expand=${EXPAND || expand}&sort=${SORT}${queryString}`);
}