/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import {
	Modal, Button,
  Form, Col, Row, Container,
} from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

function ChangeOwnershipModal (props) {
  const dispatch = useDispatch();

  const { shop } = props

  const [userId, setUserId] = useState('')
  const [user, setUser] = useState(null)

  const { userData } = useSelector(state => ({
    userData: state._users.userData,
  }))
  
  // -- user actions

  const handleHide = () => {
    props.onHide()
  }

  const onFetchName = () => {
    if (!userId) {
      alert('User Id is required')
    } else {
      dispatch({ type: 'USER_SCAN_DATA', userId })

      setTimeout(() => {
        const ud = userData.get(userId)
        setUser(ud)
      }, 1000)
    }
  }

  const onSubmit = () => {
    if (!user) {
      alert('Fetch user first!')
    } else {
      if (window.confirm('Are you sure you want to change the owner of this shop?')) {
        dispatch({
          type: 'SHOP_CHANGE_OWNER',
          payload: { _id: shop._id, data: { userId } }
        })

        props.onHide()
      }
    }
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Change Owner { !!shop && (<small> / {shop.name} / {shop._id}</small>)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>Target User Id</Form.Label>
              <Col sm={8}><Form.Control value={userId} onChange={(e) => setUserId(e.target.value)} /></Col>
            </Form.Group>

            { !!user && (
              <Form.Group as={Row}>
                <Form.Label column sm={4}>Name</Form.Label>
                <Col sm={8}>{user?.name}</Col>
              </Form.Group>
            )}
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <Button onClick={onFetchName} variant="warning" className="btn-sm">Fetch User</Button>

        <div>
          <Button onClick={onSubmit} variant="primary" className="btn-sm">Apply</Button>&nbsp;&nbsp;
          <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeOwnershipModal;
