import axios from 'axios';
import { API_SERVER } from '../../config'

export const API_RESOURCE = `${API_SERVER}/users`

export function getMany(riderStatus) {
  const statusFilter = riderStatus
    ? `&riderStatus=${riderStatus}`
    : ''

  return axios.get(`${API_RESOURCE}?role=rider${statusFilter}`)
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data)
}
