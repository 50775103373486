import axios from 'axios';
import { API_SERVER } from '../../config.js'

export const API_RESOURCE = `${API_SERVER}/users`

const LIMIT = 10
const EXPAND = 'address'

export function lookup(query) {
  return axios.get(`${API_RESOURCE}?${query}&listOnly=true`);
}

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?role=user&page=${page}&limit=${LIMIT}&expand=${EXPAND}`);
}

export function scanData(_id) {
  return axios.get(`${API_RESOURCE}/${_id}/scan-data`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}?expand=${EXPAND}`, data);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&expand=${EXPAND}`);
}