/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";

import {
  Form, Table, Dropdown,
} from "react-bootstrap";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as sduck from '../../../store/ducks/_shops.duck';
import * as pduck from '../../../store/ducks/_products.duck';

import Pagination from '../../../partials/component/Pagination'

function Products(props) {
  const { _pduck, products } = props
  const [searchText, setSearchText] = useState('')

  // -- hooks

  useEffect(() => {
    _pduck.getRecords({ soldOut: true, expand: 'shop' })
  }, [_pduck])

  // useEffect(() => {
  //   if (!shop) {
  //     _sduck.getRecord(shopId)
  //   }
  // }, [shop, _sduck, shopId])

  // -- user actions


  const onPageClick = (tPage) => {
    const query = {
      soldOut: true,
      expand: 'shop',
      page: tPage
    }

    if (searchText) {
      query.search = searchText
    }

    _pduck.doQuery(query)
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      setSearchText(text)
      _pduck.doQuery({
        soldOut: true,
        search: text,
        page: 1
      })
    }
  }

  // -- render

  return (
    <>
      <Portlet>
        <PortletHeader
          title="Sold Out Products"

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Product" onKeyPress={handlePressEnter} />
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
              <th>ID ({products.count})</th>
                <th>Shop</th>
                <th>Category</th>
                <th>Product</th>
                <th>Addons</th>
                <th>Variants</th>
                <th>Price</th>
                <th>Availability</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(products.list.values()).map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link">
                          {item._id.substr(0, 6)}
                        </a>
                      </td>
                      <td>{item.shop?.name}</td>
                      <td>{item.category || 'Uncategorized'}</td>
                      <td>
                        <img alt="" className="product-image" src={item.photos[0]} />&nbsp;&nbsp;

                        {item.name} {item.hotItem ? ' ⭐' : ''}
                      </td>
                      <td>
                        { item.addOns?.length }
                      </td>
                      <td>
                        { item.variants.length ? (
                          item.variants.map(variant => {
                            return <span key={variant.name}>{variant.name}<br/></span>
                          })
                        ) : ''}
                      </td>
                      <td>
                        { !item.variants.length ? (
                          `₱${item.price.toFixed(2)} + ${item.markup.toFixed(2)}`
                        ) : (
                          <>
                          {
                            item.variants.map(variant => {
                              return <span key={variant.name}>₱{variant?.price.toFixed(2)} + {!isNaN(variant?.markup) ? (+variant.markup).toFixed(2) : '?'}<br/></span>
                            })
                          }
                          </>
                        )}
                      </td>
                      <td>
                        {
                          item.soldOut
                            ? <span className="kt-font-danger">SOLD OUT</span>
                            : <span className="">AVAILABLE</span>
                        }
                      </td>
                      <td>
                        {
                          item.active
                            ? <span className="">Active</span>
                            : <span className="kt-font-danger">Inactive</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>
                          
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => _pduck.patchRecord(item._id, { soldOut: !item.soldOut })}>{ item.soldOut ? 'Set as Available' : 'Set as SoldOut'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => _pduck.patchRecord(item._id, { active: !item.active })}>{ item.active ? 'Set as Inactive' : 'Set as Active'}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>


          <div className='d-flex justify-content-end'>
            <Pagination
              page={products.page}
              pages={products.pages}
              onPageClick={onPageClick}
            />
          </div>

        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
    products: state._products,
	}
}

/** KEEP - multi duct binding */

const mapDispatchToProps = (dispatch) => {
  return {
    _pduck: bindActionCreators(pduck.actions, dispatch),
    _sduck: bindActionCreators(sduck.actions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);

