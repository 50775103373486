import React, { useEffect } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../partials/content/Portlet';

import {
  Spinner,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import * as duck from '../../../store/ducks/stores.duck'

function StoreDetails(props) {
  const { id } = useParams();
  const { stores, getStore } = props;

  const store = stores.list.get(id)

  // -- hooks

  useEffect(() => {
    if (!store) {
      getStore(id)
    }
  }, [store, getStore, id])

  // -- render

  if (!store) {
    return (
      <div className="center-hv">
        <Spinner animation="border" variant="primary" />
        <span>&nbsp;&nbsp;Fetching Store Details...</span>
      </div>
    );
  }

  return (
    <>

      <Portlet>
        <PortletHeader
          title={
            <>
              <Link to="/stores">Stores</Link>
              <small>/ {id}</small>
            </>
          }
        />

        <PortletBody>
          <div className="row row-no-padding row-col-separator-xl">
            <div className="col-xl-6">

              {/* STORE INFO */}

              <div className="kt-widget5">
                <div className="kt-widget5__item mb-0 pb-0">
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__pic">
                    <img alt="" className="kt-widget7__img order-detail-image" src={store.photo} />
                    </div>
                    <div className="kt-widget5__section">
                      <span className="kt-widget5__title">
                        {store.name}
                      </span>
                      <p className="kt-widget5__desc">{store.description}</p>
                      <div className="kt-widget5__info">
                        <span> {store.address}</span>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget5__content">
                    <div className="kt-widget5__stats">
                      <span className="kt-widget5__number">Phones</span>
                      <span className="kt-widget5__sales">{`G: ${store.phoneGlobe.substring(0, 4)} ${store.phoneGlobe.substring(4, 7)} ${store.phoneGlobe.substring(7, store.phoneGlobe.length)}`}</span>
                      <span className="kt-widget5__votes">{`S: ${store.phoneSmart.substring(0, 4)} ${store.phoneSmart.substring(4, 7)} ${store.phoneSmart.substring(7, store.phoneSmart.length)}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
		stores: state.stores,
	}
}

export default connect(mapStateToProps, duck.actions)(StoreDetails);
