import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_towns.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),

  isLoading: true
}

const persistConfig = {
  storage,
  key: 'elves-towns',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.stores.page

// -- action types

export const actionTypes = {
  TOWNS_LOAD: 'TOWNS_LOAD',
  TOWNS_GET: 'TOWNS_GET',
}

// -- actions

export const actions = {
  getRecords: (page) => ({ type: actionTypes.TOWNS_GET }),
  fillRecords: payload => ({ type: actionTypes.TOWNS_LOAD, payload }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.TOWNS_GET, function* querySaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.query(action.query, page)

      yield put(actions.fillRecords(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOWNS_LOAD: {
      const { page, pages, count, data } = action.payload;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    default:
      return state;
  }
});
