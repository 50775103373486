import axios from 'axios';
import { API_SERVER } from '../../config'

const LIMIT = 12
const SORT = '-createdAt'

export const API_RESOURCE = `${API_SERVER}/orders`

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&sort=${SORT}`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function post(data) {
  let queryString = ''
  return axios.post(`${API_RESOURCE}?${queryString}`, data);
}

export function query(query) {
  const { shop, search, category, page } = query

  let queryString = ''

  if (shop) queryString += `&shop=${shop}`
  if (search) queryString += `&search=${search}`
  if (category) queryString += `&category=${category}`

  return axios.get(`${API_RESOURCE}?page=${page || 1}&limit=${LIMIT}&sort=${SORT}${queryString}`);
}