import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_users.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,

  list: [],
  toastErr: null,

  // -- lookup

  lookup: new Map(),
  userData: new Map(),
}

const persistConfig = {
  storage,
  key: 'elves-users',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._users.page

// -- action types

export const actionTypes = {
  USERS_LOADED: 'USERS_LOADED',
  USERS_REQ: 'USERS_REQ',

  USERS_UPDATE_REQ: 'USERS_UPDATE_REQ',
  USERS_UPDATE_LOAD: 'USERS_UPDATE_LOAD',
  USERS_SEARCH_REQ: 'USERS_SEARCH_REQ',

  TOAST_ERR: 'TOAST_ERR',
  TOAST_CLEAR: 'TOAST_CLEAR',

  USERS_LKUP: 'USERS_LKUP',
  USERS_LKUP_DONE: 'USERS_LKUP_DONE',
  USERS_LKUP_LOAD: 'USERS_LKUP_LOAD',

  USER_SCAN_DATA: 'USER_SCAN_DATA',
  USER_LOAD_DATA: 'USER_LOAD_DATA',
}

// -- actions

export const actions = {
  getUsers: (page) => ({ type: actionTypes.USERS_REQ, page }),
  fillUsers: payload => ({ type: actionTypes.USERS_LOADED, payload }),

  patchUser: (_id, data) => ({ type: actionTypes.USERS_UPDATE_REQ, payload: { _id, data } }),
  fillUser: payload => ({ type: actionTypes.USERS_UPDATE_LOAD, payload }),
  doSearch: text => ({ type: actionTypes.USERS_SEARCH_REQ, text }),

  setToastErr: err => ({ type: actionTypes.TOAST_ERR, err }),
  toastClear: () => ({ type: actionTypes.TOAST_CLEAR }),

  lookup: (query) => ({ type: actionTypes.USERS_LKUP, query }),
  fillLookups: records => ({ type: actionTypes.USERS_LKUP_LOAD, records }),

  getUserData: (userId) => ({ type: actionTypes.USER_SCAN_DATA, userId }),
  fillUserData: (userId, data) => ({ type: actionTypes.USER_LOAD_DATA, userId, data }),
};

// -- sagas

export function* saga() {
  yield takeEvery(actionTypes.USERS_LKUP, function* lookupSaga(action) {
    try {

      const { data: records } = yield crud.lookup(action.query);
      yield put(actions.fillLookups(records));
      yield put({ type: actionTypes.USERS_LKUP_DONE })
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.USERS_REQ, function* getSaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.getMany(page);

      yield put(actions.fillUsers(response));
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.USER_SCAN_DATA, function* scanSaga(action) {
    try {
      const { data: response } = yield crud.scanData(action.userId);
      yield put(actions.fillUserData(action.userId, response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.USERS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillUser(response))
    } catch (err) {
      yield put(actions.setToastErr(err))
    }
  });

  yield takeLatest(actionTypes.USERS_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillUsers(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  // -- reducers
}

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERS_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.page
      };
    }

    // --

    case actionTypes.USERS_LOADED: {
      const { page, pages, count, data: list } = action.payload;
      return { ...state, count, page, pages, list };
    }

    // --

    case actionTypes.USERS_UPDATE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          break
        }
      }

      return { ...state }
    }

    // --

    case actionTypes.TOAST_ERR: {
      const { response } = action.err
      let toastErr = null
    
      if (response) {
        toastErr = { ...response.data }
      }

      return { ...state, toastErr }
    }

    // --

    case actionTypes.TOAST_CLEAR: {
      return { ...state, toastErr: null }
    }

    // --

    case actionTypes.USERS_LKUP_LOAD: {
      const { lookup } = state

      action.records.forEach(record => {
        lookup.set(record._id, record)
      });

      return { ...state, lookup }
    }

    // --

    case actionTypes.USER_LOAD_DATA: {
      const { userData } = state
      userData.set(action.userId, action.data)
      return { ...state, userData }
    }

    // --

    default:
      return state;
  }
});
