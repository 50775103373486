import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/markers.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),

  isLoading: true
}

const persistConfig = {
  storage,
  key: 'elves-markers',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.stores.page

// -- action types

export const actionTypes = {
  MARKERS_REQ: 'MARKERS_REQ',
  MARKERS_LOAD: 'MARKERS_LOAD',
  MARKERS_QUERY_REQ: 'MARKERS_QUERY_REQ',
  MARKERS_SEARCH_REQ: 'MARKERS_SEARCH_REQ',

  MARKER_LOAD: 'MARKER_LOAD',
  MARKER_UPDATE_REQ: 'MARKER_UPDATE_REQ',
}

// -- actions

export const actions = {
  getMarkers: (page) => ({ type: actionTypes.MARKERS_REQ }),
  fillMarkers: payload => ({ type: actionTypes.MARKERS_LOAD, payload }),

  doSearch: text => ({ type: actionTypes.MARKERS_SEARCH_REQ, text }),
  doQuery: query => ({ type: actionTypes.MARKERS_QUERY_REQ, query }),

  patchMarker: (_id, data) => ({ type: actionTypes.MARKER_UPDATE_REQ, payload: { _id, data } }),
  fillMarker: payload => ({ type: actionTypes.MARKER_LOAD, payload }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.MARKERS_REQ, function* getSaga() {
    try {
      const page = yield select(getCurrentPage);

      const { data: response } = yield crud.getMany(page)

      yield put(actions.fillMarkers(response));
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.MARKERS_QUERY_REQ, function* querySaga(action) {
    try {
      const { data: response } = yield crud.query(action.query)

      yield put(actions.fillMarkers(response));
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.MARKERS_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillMarkers(response));
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.MARKER_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillMarker(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MARKERS_QUERY_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.query.page };
    }

    case actionTypes.MARKERS_LOAD: {
      const { data, page, pages, count } = action.payload;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    case actionTypes.MARKER_LOAD: {
      const { _id } = action.payload
      const { list } = state

      list.set(_id, action.payload)

      return { ...state, list, isLoading: false, }
    }

    default:
      return state;
  }
});
