import axios from 'axios';
import { API_SERVER } from '../../config'

const LIMIT = 12
const EXPAND = 'store'
const SORT = 'category name'

export const API_RESOURCE = `${API_SERVER}/products`

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&expand=${EXPAND}`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}?expand=${EXPAND}`, data);
}

export function post(data) {
  return axios.post(`${API_RESOURCE}?expand=${EXPAND}`, data);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&expand=${EXPAND}`);
}

export function getByStore(storeId, limit) {
  return axios.get(`${API_RESOURCE}?store=${storeId}&limit=${limit || LIMIT}&expand=${EXPAND}&sort=${SORT}`);
}

export function query(query, page) {
  const { store, search, category } = query

  let queryString = ''

  if (store) queryString += `&store=${store}`
  if (search) queryString += `&search=${search}`
  if (category) queryString += `&category=${category}`

  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&expand=${EXPAND}&sort=${SORT}${queryString}`);
}