import 'firebase/auth'
import firebase from "firebase/app";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

/**
 * setupAxios: Inject axios interceptors
 * @see https://github.com/axios/axios#interceptors
 * 
 * @param {*} axios   : axios instance
 * @param {*} store   : redux store
 */

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    async (config) => {
      const { auth: { authToken }} = store.getState();

      config.headers['x-standard-version'] = `2.0.0`;

      if (firebase.auth().currentUser) {
        const token = await firebase.auth().currentUser.getIdToken(true)
        config.headers.Authorization = `Bearer ${token}`;
      } else if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}

/**
 * removeStorage: removes a key from localStorage and its sibling expiracy key
 * 
 * @param {string} key  : localStorage key to remove
 * @returns {boolean}   : telling if operation succeeded
 */

export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/** 
 * getStorage() : retrieves a key from localStorage previously set with setStorage().
 * 
 * @param {string} key  : localStorage key
 * @returns {string}    : value of localStorage key
 * @returns {null}      : in case of expired key or failure
 */

export function getStorage(key) {
  const now = Date.now(); // epoch time, lets deal only with integer
  let expiresIn = localStorage.getItem(key + "_expiresIn"); // set expiration for storage

  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}

/**
 * setStorage: writes a key into localStorage setting a expire time
 * 
 * @param {string} key      : localStorage key
 * @param {string} value    : localStorage value
 * @param {nubmer} expires  : number of seconds from now to expire the key
 * @returns {boolean}       : telling if operation succeeded
 */

export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
