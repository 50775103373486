import axios from 'axios';
import { API_SERVER } from '../../config'

const LIMIT = 12
const SORT = 'name'

export const API_RESOURCE = `${API_SERVER}/shops`

export function lookup(query) {
  return axios.get(`${API_RESOURCE}?${query}&listOnly=true`);
}

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&sort=${SORT}`);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&sort=${SORT}`);
}

export function post(data) {
  return axios.post(`${API_RESOURCE}`, data);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function read(_id) {
  return axios.get(`${API_RESOURCE}/${_id}`);
}

export function del(_id) {
  return axios.delete(`${API_RESOURCE}/${_id}`);
}

export function query(query) {
  const { active, sort, page, search } = query

  let queryString = ''

  if (active) queryString += `&active=${active}`
  if (search) queryString += `&search=${search}`

  return axios.get(`${API_RESOURCE}?page=${page || 1}&limit=${LIMIT}&sort=${sort || SORT}${queryString}`);
}

export function changeOwner(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}/change-owner`, data);
}