import axios from 'axios';
import { API_SERVER } from '../../config.js'

export const API_RESOURCE = `${API_SERVER}/users`

const LIMIT = 20
const EXPAND = ''

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?role=rider&role=admin&page=${page}&limit=${LIMIT}&expand=${EXPAND}`);
}

export function getRiderStat(riderId) {
  return axios.get(`${API_RESOURCE}/order-stat2/${riderId}`);
}

export function ackRiderStat(riderId) {
  return axios.patch(`${API_RESOURCE}/${riderId}/ack-stats`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}?expand=${EXPAND}`, data);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&expand=${EXPAND}`);
}