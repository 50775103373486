import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/riders.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,

  list: [],
  index: new Set(),

  toastErr: null
}

const persistConfig = {
  storage,
  key: 'elves-riders',
  blacklist: Object.keys(initialState)
}

// -- action types

export const actionTypes = {
  RIDERS_LOADED: 'RIDERS_LOADED',
  RIDERS_REQUEST: 'RIDERS_REQUEST',

  RIDERS_UPDATE_REQ: 'RIDERS_UPDATE_REQ',
  RIDERS_UPDATE_LOAD: 'RIDERS_UPDATE_LOAD',

  TOAST_ERR: 'TOAST_ERR',
  TOAST_CLEAR: 'TOAST_CLEAR'
}

// -- action

export const actions = {
  getRiders: (status) => ({ type: actionTypes.RIDERS_REQUEST, status }),
  fillUsers: payload => ({ type: actionTypes.RIDERS_LOADED, payload }),

  updateUser: (_id, data) => ({ type: actionTypes.RIDERS_UPDATE_REQ, payload: { _id, data } }),
  fillUser: payload => ({ type: actionTypes.RIDERS_UPDATE_LOAD, payload }),

  // setToastErr: err => ({ type: actionTypes.TOAST_ERR, err }),
  // toastClear: () => ({ type: actionTypes.TOAST_CLEAR }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.RIDERS_REQUEST, function* getSaga(action) {
    try {
      const { data: response } = yield crud.getMany(action.status);
      yield put(actions.fillUsers(response));  
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  })

  yield takeLatest(actionTypes.RIDERS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillUser(response))
    } catch (err) {
      yield put(actions.setToastErr(err))
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RIDERS_LOADED: {
      const { page, pages, count, data } = action.payload
      const { list, index } = state

      data.forEach(item => {
        if (!index.has(item._id)) {
          index.add(item._id)
          list.push(item)
        }
      });

      return { ...state, count, page, pages, list, index }
    }

    case actionTypes.RIDERS_UPDATE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          break
        }
      }

      return { ...state }
    }

    case actionTypes.TOAST_ERR: {
      const { response } = action.err
      let toastErr = null
    
      if (response) {
        toastErr = { ...response.data }
      }

      return { ...state, toastErr }
    }

    case actionTypes.TOAST_CLEAR: {
      return { ...state, toastErr: null }
    }

    default:
      return state;
  }
});
