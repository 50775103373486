import React, { useEffect } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../partials/content/Portlet';

import {
  Table, Dropdown, Form,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import * as duck from '../../store/ducks/addons.duck';
import Pagination from '../../partials/component/Pagination';

function Addons(props) {
  const { getAddons, addons, doSearch, patchAddon } = props;
  const currentPage = addons.page || 1;

  // -- hooks

  useEffect(() => {
    getAddons(currentPage)
  }, [getAddons, currentPage])


  // -- user actions

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        getAddons(currentPage)
      }
    }
  }

  const updateAddon = (_id, data) => {
    patchAddon(_id, data)
  }

  // -- render

  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            <>
              Addons{' '}
              <small>All store addons.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Addon" onKeyPress={handlePressEnter} />
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Store</th>
                <th>Name</th>
                <th>Price</th>
                <th>Availability</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                addons.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.store.name}</td>
                      <td>{item.name}</td>
                      <td>₱ {item.price.toFixed(2)}</td>
                      <td>
                        {
                          item.soldOut
                            ? <span className="kt-font-danger">SOLD OUT</span>
                            : <span className="kt-font-success">AVAILABLE</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => updateAddon(item._id, { soldOut: !item.soldOut })}>{ item.soldOut ? 'Set as Available' : 'Set as Sold Out'}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={addons.page}
              pages={addons.pages}
              onPageClick={getAddons}
            />
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
		addons: state.addons,
	}
}

export default connect(mapStateToProps, duck.actions)(Addons);
