import storage from 'redux-persist/lib/storage';
import * as routerHelpers from '../../router/RouterHelpers';

import { persistReducer } from 'redux-persist';
import { put, takeLatest } from 'redux-saga/effects';

import { getSelf, firebaseLogout } from '../../crud/auth.crud';


export const actionTypes = {
  AUTH_SET_TOKEN: '[AUTH] SET_TOKEN',
  AUTH_USER_LOGIN: '[AUTH] USER_LOGIN',
  AUTH_USER_LOADED: '[AUTH] USER_LOADED',
  AUTH_USER_LOGOUT: '[AUTH] USER_LOGOUT',
};

const initialState = {
  user: undefined,
  authToken: undefined,
};

const persistConfig = {
  storage,
  key: 'elves-auth',
  whitelist: Object.keys(initialState)
}

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_USER_LOGIN: {
      const { authToken } = action.payload;
      return { authToken, user: undefined };
    }

    case actionTypes.AUTH_USER_LOADED: {
      const { user } = action.payload;
      return { ...state, user };
    }

    case actionTypes.AUTH_SET_TOKEN: {
      const { authToken } = action.payload;
      return { ...state, authToken };
    }

    case actionTypes.AUTH_USER_LOGOUT: {
      routerHelpers.forgotLastLocation();
      return initialState;
    }

    default: return state;
  }
});

export const actions = {
  _login: authToken => ({ type: actionTypes.AUTH_USER_LOGIN, payload: { authToken } }),
  _fillMongoUser: user => ({ type: actionTypes.AUTH_USER_LOADED, payload: { user } }),
  _logout: () => ({ type: actionTypes.AUTH_USER_LOGOUT }),
};

export function* saga() {
  yield takeLatest(actionTypes.AUTH_USER_LOGOUT, function* logoutSaga() {
    yield firebaseLogout();
  });

  yield takeLatest(actionTypes.AUTH_USER_LOGIN, function* loginSaga() {
    const { data: user } = yield getSelf();

    if (user.role !== 'admin') {
      throw new Error('Not an admin!')
    }

    yield put(actions._fillMongoUser(user));
  });
}
