// -- DEV
// npm run build && firebase deploy --only hosting:backoffice-local-elves-dev

// export const API_SERVER = 'https://dev-api.local-elves.com/v2';
// export const TEMP_PHOTO = 'https://res.cloudinary.com/traventu/image/upload/v1630724432/dev-assets/default-pic.webp';
// export const TEMP_SHOP_ADDRESS = 'xavqTL0MrjJvyRw3yP_DS';

// export const FIREBASE_OPTIONS = {
//   apiKey: "AIzaSyDwbacGEdlJgmhTWDrkOS8watJI7zyx52c",
//   authDomain: "local-elves-dev.firebaseapp.com",
//   databaseURL: "https://local-elves-dev.firebaseio.com"
// }

// -- PROD
// npm run build && firebase deploy --only hosting:backoffice-local-elves

export const API_SERVER = 'https://api.local-elves.com/v2';
export const TEMP_PHOTO = 'https://res.cloudinary.com/higher-elves/image/upload/v1635603988/dev-assets/def_pd02r3.jpg';
export const TEMP_SHOP_ADDRESS = 'v-IdHrXklMXpBk2wmJ8Fy';

export const FIREBASE_OPTIONS = {
  apiKey: "AIzaSyCN3Xs-qH5GiQ8vrLMRA9rFNN77X27aB7I",
  authDomain: "local-elves-8f13b.firebaseapp.com",
  databaseURL: "https://local-elves-8f13b.firebaseio.com",
};

// -- COMMON

export const WEB_MAP_KEY = 'AIzaSyDkIu92i8tEFUH1bbcwEydlmCrq17uziJc';
