/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import firebase from 'firebase/app';

import { FIREBASE_OPTIONS } from './config'

import { setupAxios } from "./_metronic";
import store, { persistor } from "./app/store/store";
import { actionTypes } from './app/store/ducks/auth.duck';

import App from "./App";

import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

setupAxios(axios, store);

firebase.initializeApp(FIREBASE_OPTIONS)

firebase.auth().onAuthStateChanged(async function(user) {
  if (user) {
    const authToken = await user.getIdToken()
    store.dispatch({ type: actionTypes.AUTH_SET_TOKEN, payload: { authToken } })
  } else {
    console.log('--- No user is signed in.')
  }
});

ReactDOM.render(
  <App
    store={store}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById("root")
);
