import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Builder from "./Builder";
import Dashboard from "./Dashboard";

import Orders from "./orders/Orders";
import OrderDetails from "./orders/OrderDetails";

import Stores from "./stores/Stores";
import StoreDetails from "./stores/StoreDetails";

import Products from "./products/Products";

import Riders from "./Riders";
import Addons from "./Addons";
import Fences from "./Fences";
import MarkerList from "./MarkerList";

import Customers from "./Customers";
import MapMarkers from "./MapMarkers";

// --

import _Users from "./_Users";
import _Riders from "./_Riders";
import _RiderExpense from "./_RiderExpense";
import _Shops from "./_shops/_Shops";
import _Products from "./_shops/_Products";
import _Orders from "./_orders/_Orders";
import _Soldouts from "./_soldouts/_Soldouts";


import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        <Route path="/_shops/:id/:name" component={_Products} />
        <Route path="/_shops" component={_Shops} />
        <Route path="/_users" component={_Users} />
        <Route path="/_orders" component={_Orders} />
        <Route path="/_soldouts" component={_Soldouts} />

        <Route path="/_riders/:id/:name" component={_RiderExpense} />
        <Route path="/_riders" component={_Riders} />

        <Route path="/riders" component={Riders} />
        <Route path="/addons" component={Addons} />
        <Route path="/fences" component={Fences} />
        <Route path="/products" component={Products} />
        <Route path="/markers" component={MapMarkers} />
        <Route path="/customers" component={Customers} />
        <Route path="/marker-list" component={MarkerList} />

        <Route path="/stores/:id" component={StoreDetails} />
        <Route path="/stores" component={Stores} />
        
        <Route path="/orders/:id" component={OrderDetails} />
        <Route path="/orders" component={Orders} />

        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
