import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_riders.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,

  list: [],
  toastErr: null,

  riderStat: new Map(),
}

const persistConfig = {
  storage,
  key: 'elves-riders',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._riders.page

// -- action types

export const actionTypes = {
  RIDERS2_LOADED: 'RIDERS2_LOADED',
  RIDERS2_REQUEST: 'RIDERS2_REQUEST',

  RIDERS2_UPDATE_REQ: 'RIDERS2_UPDATE_REQ',
  RIDERS2_UPDATE_LOAD: 'RIDERS2_UPDATE_LOAD',
  RIDERS2_SEARCH_REQ: 'RIDERS2_SEARCH_REQ',

  RIDER2_TOAST_ERR: 'RIDER2_TOAST_ERR',
  RIDER2_TOAST_CLEAR: 'RIDER2_TOAST_CLEAR',

  GET_RIDER_STAT: 'GET_RIDER_STAT',
  FILL_RIDER_STAT: 'FILL_RIDER_STAT',
  ACK_RIDER_STAT: 'ACK_RIDER_STAT',
}

// -- actions

export const actions = {
  getRecords: (page) => ({ type: actionTypes.RIDERS2_REQUEST, page }),
  fillRecords: payload => ({ type: actionTypes.RIDERS2_LOADED, payload }),

  patchRecord: (_id, data) => ({ type: actionTypes.RIDERS2_UPDATE_REQ, payload: { _id, data } }),
  fillRecord: payload => ({ type: actionTypes.RIDERS2_UPDATE_LOAD, payload }),
  doSearch: text => ({ type: actionTypes.RIDERS2_SEARCH_REQ, text }),

  setToastErr: err => ({ type: actionTypes.RIDER2_TOAST_ERR, err }),
  toastClear: () => ({ type: actionTypes.RIDER2_TOAST_CLEAR }),

  getRiderStat: (userId) => ({ type: actionTypes.GET_RIDER_STAT, userId }),
  fillRiderStat: (userId, data) => ({ type: actionTypes.FILL_RIDER_STAT, userId, data }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.RIDERS2_REQUEST, function* getSaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.getMany(page);

      yield put(actions.fillRecords(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.RIDERS2_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillRecord(response))
    } catch (err) {
      yield put(actions.setToastErr(err))
    }
  });

  yield takeLatest(actionTypes.RIDERS2_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillRecords(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.GET_RIDER_STAT, function* scanSaga(action) {
    try {
      const { data: response } = yield crud.getRiderStat(action.userId);

      yield put(actions.fillRiderStat(action.userId, response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.ACK_RIDER_STAT, function* ackStatSaga(action) {
    try {
      yield crud.ackRiderStat(action.userId);

      const resp = [{
        _id: null,
        totalDeliveryFee: 0,
        totalExpense: 0,
        totalMarkup: 0,
        count: 0
      }]

      yield put(actions.fillRiderStat(action.userId, resp));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  // -- reducers
}

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RIDERS2_REQUEST: {
      return { ...state, page: action.page };
    }

    case actionTypes.RIDERS2_LOADED: {
      const { page, pages, count, data: list } = action.payload;
      return { ...state, count, page, pages, list };
    }

    case actionTypes.RIDERS2_UPDATE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          break
        }
      }

      return { ...state }
    }

    case actionTypes.RIDER2_TOAST_ERR: {
      const { response } = action.err
      let toastErr = null
    
      if (response) {
        toastErr = { ...response.data }
      }

      return { ...state, toastErr }
    }

    case actionTypes.RIDER2_TOAST_CLEAR: {
      return { ...state, toastErr: null }
    }

    // --

    case actionTypes.FILL_RIDER_STAT: {
      const { riderStat } = state
      riderStat.set(action.userId, action.data[0])
      return { ...state, riderStat }
    }

    default:
      return state;
  }
});
