/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';

import {
  Form, Table, Button,
  Dropdown, Spinner,
} from 'react-bootstrap';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as duck from '../../../store/ducks/_shops.duck';

import ShopModal from './_ShopModal'
import ChangeOwnershipModal from './_ShopCOModal'
import Pagination from '../../../partials/component/Pagination'

function Shops (props) {
  const { getRecords, shops, doSearch, patchRecord, addRecord, delRecord } = props;
  const currentPage = shops.page || 1;

  const [editShop, setEditShop] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showCOModal, setShowCOModal] = useState(false)

  // -- hooks

  useEffect(() => {
    getRecords(currentPage)
  }, [getRecords, currentPage])

  // -- helpers

  const to12h = (mTime) => {
    const ampm = mTime < 1300 ? 'AM' : 'PM';

    if (mTime >= 1300) {
      mTime = mTime - 1200;
    }

    const str = mTime < 1000 ? `0${mTime}` : `${mTime}`;
    const time = `${str[1]}:${str[2]}${str[3]} ${ampm}`;

    return +str[0] ? `${str[0]}${time}` : time;
  };

  const isStoreOpen = ({ storeHours }) => {
    const dt = new Date();
    const mm = dt.getMinutes();
    const time = +`${dt.getHours()}${mm > 9 ? mm : `0${mm}`}`;

    if (!storeHours.length && !storeHours.length) {
      return true;
    }
  
    // if (!schedDays.includes(day)) {
    //   return false;
    // }

    if (storeHours[0] < storeHours[1]) {
      return time > storeHours[0] && time < storeHours[1];
    } else {
      const isBelowClosing = time > -1 && time < storeHours[1];
      const isAheadFromOpening = time > storeHours[0] && time < 2400;

      return isAheadFromOpening || isBelowClosing;
    }
  };

  // -- user actions

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        getRecords(currentPage)
      }
    }
  }

  const onEditShop = (shop) => {
    setEditShop(shop)
    setShowModal(true)
  }

  const handleModalSubmit = (data) => {
    if (editShop) {
      patchRecord(editShop._id, data)
    } else {
      addRecord(data)
    }
  }

  const onRecordDelete = (_id) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      delRecord(_id)
    }
  }

  const onChangeOwner = (shop) => {
    setEditShop(shop)
    setShowCOModal(true)
  }

  // -- render

  return (
    <>
      <ShopModal
        show={showModal}
        shop={editShop}
        onHide={(cb) => setShowModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <ChangeOwnershipModal
        show={showCOModal}
        shop={editShop}
        onHide={(cb) => setShowCOModal(false)}
        // onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Shops{' '}
              <small>All registered stores.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Store" onKeyPress={handlePressEnter} />

              &nbsp;
              &nbsp;
              <Button
                variant="secondary"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
                onClick={() => { setShowModal(true); setEditShop(null)}} >Add&nbsp;New
              </Button>
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID ({shops.count})</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Ranks</th>
                <th>Store Hours</th>
                <th>Type</th>
                <th>Status</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(shops.list.values()).map((item, index) => {
                  const isOpen = isStoreOpen(item)

                  return (
                    <tr key={item._id}>
                      <td>
                        <Link to={`/_shops/${item._id}/${item.name}`} className="kt-footer__menu-link kt-link">
                          {item._id.substr(0, 6)}
                        </Link>
                      </td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditShop(item)}>
                          {item.name}
                        </a>
                      </td>
                      <td>{item.phone}</td>
                      <td>{item.rank}</td>
                      <td>
                        {
                          <span >
                            { !item.storeHours.length ? 'Always Open' : `${to12h(item.storeHours[0])} - ${to12h(item.storeHours[1])} `}
                          </span>
                        }
                      </td>
                      <td>{item.type}</td>
                      <td>
                        {
                          item.closed
                            ? <span className="kt-font-danger">FORCED CLOSED</span>
                            : <span className={isOpen ? '' : 'kt-font-danger'}>{ isOpen ? 'OPEN ' : 'CLOSED' }</span>
                        }
                      </td>
                      <td>
                        {
                          item.active
                            ? <span className="">Active</span>
                            : <span className="kt-font-danger">Inactive</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => patchRecord(item._id, { active: !item.active })}>{ item.active ? 'Set as Inactive' : 'Set as Active'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => patchRecord(item._id, { closed: !item.closed })}>{ item.closed ? 'Clear Forced Close' : 'Forced Close'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => onChangeOwner(item)}>Change Ownership</Dropdown.Item>
                            { !item.active && (<Dropdown.Item onClick={() => onRecordDelete(item._id)}>Delete</Dropdown.Item>)}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          { shops.isLoading && (
            <div className="center-hv">
              <Spinner animation="border" variant="primary" />
              <span>&nbsp;&nbsp;Fetching Data..</span>
            </div>
          )}

          { !shops.isLoading && !shops.list.size && (
            <div className="center-hv">
              <span>No records found!</span>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <Pagination
              page={shops.page}
              pages={shops.pages}
              onPageClick={getRecords}
            />
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}



const mapStateToProps = (state) => {
	return {
		shops: state._shops,
	}
}

export default connect(mapStateToProps, duck.actions)(Shops);
