import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/customers.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,

  list: [],
  toastErr: null
}

const persistConfig = {
  storage,
  key: 'elves-customers',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.customers.page

// -- action types

export const actionTypes = {
  CUSTOMERS_LOADED: 'CUSTOMERS_LOADED',
  CUSTOMERS_REQUEST: 'CUSTOMERS_REQUEST',

  CUSTOMERS_UPDATE_REQ: 'CUSTOMERS_UPDATE_REQ',
  CUSTOMERS_UPDATE_LOAD: 'CUSTOMERS_UPDATE_LOAD',
  CUSTOMERS_SEARCH_REQ: 'CUSTOMERS_SEARCH_REQ',

  TOAST_ERR: 'TOAST_ERR',
  TOAST_CLEAR: 'TOAST_CLEAR'
}

// -- actions

export const actions = {
  getCustomers: (page) => ({ type: actionTypes.CUSTOMERS_REQUEST, page }),
  fillUsers: payload => ({ type: actionTypes.CUSTOMERS_LOADED, payload }),

  patchCustomer: (_id, data) => ({ type: actionTypes.CUSTOMERS_UPDATE_REQ, payload: { _id, data } }),
  fillUser: payload => ({ type: actionTypes.CUSTOMERS_UPDATE_LOAD, payload }),
  doSearch: text => ({ type: actionTypes.CUSTOMERS_SEARCH_REQ, text }),

  setToastErr: err => ({ type: actionTypes.TOAST_ERR, err }),
  toastClear: () => ({ type: actionTypes.TOAST_CLEAR }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.CUSTOMERS_REQUEST, function* getSaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.getMany(page);

      yield put(actions.fillUsers(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.CUSTOMERS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillUser(response))
    } catch (err) {
      yield put(actions.setToastErr(err))
    }
  });

  yield takeLatest(actionTypes.CUSTOMERS_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillUsers(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  // -- reducers
}

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMERS_REQUEST: {
      return { ...state, page: action.page };
    }

    case actionTypes.CUSTOMERS_LOADED: {
      const { page, pages, count, data: list } = action.payload;
      return { ...state, count, page, pages, list };
    }

    case actionTypes.CUSTOMERS_UPDATE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          break
        }
      }

      return { ...state }
    }

    case actionTypes.TOAST_ERR: {
      const { response } = action.err
      let toastErr = null
    
      if (response) {
        toastErr = { ...response.data }
      }

      return { ...state, toastErr }
    }

    case actionTypes.TOAST_CLEAR: {
      return { ...state, toastErr: null }
    }

    default:
      return state;
  }
});
