import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_orders.crud'
import { expand } from './utils'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),

  isLoading: true
}

const persistConfig = {
  storage,
  key: 'elves-orders',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._orders.page

// -- action types

export const actionTypes = {
  ORDERS_LOAD: 'ORDERS_LOAD',
  ORDERS_REQ: 'ORDERS_REQ',

  ORDER_UPD: 'ORDER_UPD',
  ORDER_LOAD: 'ORDER_LOAD',
}

// -- action

export const actions = {
  getRecords: page => ({ type: actionTypes.ORDERS_REQ, page }),
  fillRecords: response => ({ type: actionTypes.ORDERS_LOAD, response }),

  patchRecord: (_id, data) => ({ type: actionTypes.ORDER_UPD, payload: { _id, data } }),
  fillRecord: record => ({ type: actionTypes.ORDER_LOAD, record }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.ORDERS_REQ, function* querySaga(action) {
    try {
      const state = yield select();
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.getMany(page)

      yield expand(state, response.data, 'shop', 'rider', 'owner', 'destination')
      yield put(actions.fillRecords(response))

    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.ORDER_UPD, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const state = yield select();
      const { data: record } = yield crud.patch(_id, data)
      
      yield expand(state, [record], 'shop', 'rider', 'owner', 'destination')
      yield put(actions.fillRecord(record))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {

    // --

    case actionTypes.ORDERS_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.page
      };
    }

    // --

    case actionTypes.ORDERS_LOAD: {
      const { page, pages, count, data } = action.response;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    // --

    case actionTypes.ORDER_LOAD: {
      const { _id } = action.record
      const { list } = state

      list.set(_id, action.record)

      return { ...state, list, isLoading: false, }
    }

    default:
      return state;
  }
});
