/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../partials/content/Portlet';

import {
  Table, Dropdown, Form,
  Modal, Button, Col, Row, Container,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import * as duck from '../../store/ducks/markers.duck';
import Pagination from '../../partials/component/Pagination';

const TEMP_PRICE = {
  "BULAN_G0": 30,
  "BULAN_G1": 35,
  "BULAN_G2": 40,
  "BULAN_G3": 45,
  "BULAN_G4": 50,
  "BULAN_G5": 55,
  "BULAN_G6": 60,
  "BULAN_G7": 65,
  "BULAN_G8": 70,
  "BULAN_G9": 75,
  "BULAN_G10": 80,
  "BULAN_G11": 85,
  "BULAN_G12": 90,
  "BULAN_G13": 100,
  "BULAN_G14": 110,
  "BULAN_G15": 120,
  "BULAN_G16": 130,
  "BULAN_G17": 140,
  "BULAN_G18": 150,
  "BULAN_G19": 160,
  "BULAN_G20": 170,
  "BULAN_G21": 180,
  "BULAN_G22": 190,
  "BULAN_G23": 200,
}

function MarkerList(props) {
  const { markers, doQuery, doSearch, patchMarker } = props;
  const currentPage = markers.page || 1;

  const [showModal, setShowModal] = useState(false)
  const [markerObj, setMarkerObj] = useState(null)

  // -- hooks

  useEffect(() => {
    doQuery({
      page: currentPage,
      sort: 'name',
      limit: 12,
    })
  }, [doQuery, currentPage])


  // -- user actions

  const getPaginatedMarkers = (page) => {
    doQuery({
      page,
      limit: 12,
      sort: 'name',
    })
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        doQuery({ page: 1, limit: 12, sort: 'name' })
      }
    }
  }

  const updateMarker = (_id, data) => {
    patchMarker(_id, data)
  }

  const onEditMarker = (marker) => {
    setMarkerObj(marker)
    setShowModal(true)
  }

  const handleModalSubmit = (data) => {
    if (markerObj) {
      patchMarker(markerObj._id, data)
    }
  }

  // -- render

  return (
    <>
      <MarkerModal
        show={showModal}
        marker={markerObj}
        onHide={(cb) => setShowModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Marker List
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Marker" onKeyPress={handlePressEnter} />
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Price</th>
                <th>Area Group</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(markers.list.values()).map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditMarker(markers.list.get(item._id))}>
                          {item._id}
                        </a>
                      </td>
                      <td>{item.name}</td>
                      <td>₱{TEMP_PRICE[item.areaGroup].toFixed(2)}</td>
                      <td>{item.areaGroup}</td>
                      <td>
                        {
                          item.isActive
                            ? <span className="">Active</span>
                            : <span className="kt-font-danger">Inactive</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="default" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => updateMarker(item._id, { isActive: !item.isActive })}>{ item.isActive ? 'Set as Inactive' : 'Set as Active'}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={markers.page}
              pages={markers.pages}
              onPageClick={getPaginatedMarkers}
            />
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}

function MarkerModal (props) {
  const { marker } = props

  const [name, setName] = useState('')
  const [areaGroup, setAreaGroup] = useState('')

  useEffect(() => {
    if (marker) {
      setName(marker.name)
      setAreaGroup(marker.areaGroup)
    }
  }, [marker]);

  const handleSubmit = () => {
    const capturedValues = {
      name,
      areaGroup
    }

    if (marker) {
      props.onSubmit(capturedValues)
    }

    handleHide()
  }

  const handleHide = () => {
    setName('')
    setAreaGroup('')
    props.onHide()
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Marker</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>Marker</Form.Label>
              <Col sm={9}><Form.Control type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} /></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={3}>Description</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" onChange={(e) => { setAreaGroup(e.target.value.split(' - ')[0])}} value={areaGroup ? `${areaGroup} - ₱${TEMP_PRICE[areaGroup].toFixed(2)}` : ''}>
                  { Object.keys(TEMP_PRICE).map(key => (
                    <option key={key}>{key} - ₱{TEMP_PRICE[key].toFixed(2)}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ marker ? 'Save' : 'Submit'}</Button>
        <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		markers: state.markers,
	}
}

export default connect(mapStateToProps, duck.actions)(MarkerList);
