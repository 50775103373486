import axios from 'axios';
import { API_SERVER } from '../../config.js'

export const API_RESOURCE = `${API_SERVER}/expenses`

const LIMIT = 10

export function getMany(page = 1, riderId) {
  return axios.get(`${API_RESOURCE}?rider=${riderId}&page=${page}&limit=${LIMIT}&sort=-createdAt`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}`);
}