/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";

import {
  Form, Table, Dropdown, Button,
} from "react-bootstrap";

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import { useParams } from "react-router-dom";

import * as sduck from '../../../store/ducks/_shops.duck';
import * as pduck from '../../../store/ducks/_products.duck';

import ProductModal from './_ProductModal'
import SelectSearch from 'react-select-search'
import Pagination from '../../../partials/component/Pagination'

function Products(props) {
  const { id: shopId, name } = useParams()
  const { _sduck, _pduck, products } = props

  const { shop } = useSelector(state => ({
    shop: state._shops.list.get(shopId)
  }))

  const [showModal, setShowModal] = useState(false)
  const [editProduct, setEditProduct] = useState(null)
  
  const [searchText, setSearchText] = useState('')

  // -- hooks

  useEffect(() => {
    _pduck.getRecords({ shop: shopId })
  }, [_pduck, shopId])

  useEffect(() => {
    if (!shop) {
      _sduck.getRecord(shopId)
    }
  }, [shop, _sduck, shopId])

  // -- user actions

  const onEditProduct = (product) => {
    setEditProduct({ ...product })
    setShowModal(true)
  }

  const handleModalSbmit = (data) => {
    if (editProduct) {
      _pduck.patchRecord(editProduct._id, data)
    } else {
      _pduck.addRecord(data)
    }
  }

  const onPageClick = (tPage) => {
    _pduck.doQuery({ shop: shopId, search: searchText, page: tPage })
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      setSearchText(text)
      _pduck.doQuery({ shop: shopId, search: text, page: 1 })
    }
  }

  const onCategoryChange = (cat) => {
    const qry = { shop: shopId, search: searchText, page: 1, category: cat }

    if (cat === 'All') {
      delete qry.category
    }

    _pduck.doQuery(qry)
  }

  // -- render

  return (
    <>
      <ProductModal
        show={showModal}
        shop={shop}
        // options={options}
        product={editProduct}
        onHide={() => setShowModal(false)}
        onSubmit={data => handleModalSbmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              <Link to="/_shops">Shops </Link>
               / <code>{name}</code>&nbsp;
              <small>{shopId}</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>

              { shop && !!shop.categories?.length && (
                <div className="row">
                  <div className="col-sm-4">
                    <SelectSearch
                      options={[{ name: 'All', value: 'All' }, ...shop.categories.map((cat) => ({ name: cat, value: cat }))]} 
                      placeholder="Choose Category" 
                      value="All"
                      onChange={onCategoryChange}
                    />
                  </div>
                </div>
              )}

              &nbsp;
              <Form.Control size="sm" type="text" placeholder="Search Product" onKeyPress={handlePressEnter} />
              &nbsp;
              &nbsp;
              <Button
                variant="secondary"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
                onClick={() => { setEditProduct(null); setShowModal(true) }} >Add&nbsp;New
              </Button>

            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
              <th>ID ({products.count})</th>
                <th>Category</th>
                <th>Product</th>
                <th>Tags</th>
                <th>Sold</th>
                <th>Variants</th>
                <th>Price</th>
                <th>Availability</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(products.list.values()).map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditProduct(item)}>
                          {item._id.substr(0, 6)}
                        </a>
                      </td>
                      <td>{item.category || 'Uncategorized'}</td>
                      <td>
                        <img alt="" className="product-image" src={item.photos[0]} />&nbsp;&nbsp;

                        {item.name} {item.hotItem ? ' ⭐' : ''}
                      </td>
                      <td>
                        {item.tags}
                      </td>
                      <td>
                        { item.sold }
                      </td>
                      <td>
                        { item.variants.length ? (
                          item.variants.map(variant => {
                            return <span key={variant.name}>{variant.name}<br/></span>
                          })
                        ) : ''}
                      </td>
                      <td>
                        { !item.variants.length ? (
                          `₱${item.price.toFixed(2)} + ${item.markup.toFixed(2)}`
                        ) : (
                          <>
                          {
                            item.variants.map(variant => {
                              return <span key={variant.name}>₱{variant?.price.toFixed(2)} + {!isNaN(variant?.markup) ? (+variant.markup).toFixed(2) : '?'}<br/></span>
                            })
                          }
                          </>
                        )}
                      </td>
                      <td>
                        {
                          item.soldOut
                            ? <span className="kt-font-danger">SOLD OUT</span>
                            : <span className="">AVAILABLE</span>
                        }
                      </td>
                      <td>
                        {
                          item.active
                            ? <span className="">Active</span>
                            : <span className="kt-font-danger">Inactive</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>
                          
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => _pduck.patchRecord(item._id, { soldOut: !item.soldOut })}>{ item.soldOut ? 'Set as Available' : 'Set as SoldOut'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => _pduck.patchRecord(item._id, { active: !item.active })}>{ item.active ? 'Set as Inactive' : 'Set as Active'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => onEditProduct(products.list.get(item._id))}>Edit Product</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>


          <div className='d-flex justify-content-end'>
            <Pagination
              page={products.page}
              pages={products.pages}
              onPageClick={onPageClick}
            />
          </div>

        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = (state) => {
	return {
    products: state._products,
	}
}

/** KEEP - multi duct binding */

const mapDispatchToProps = (dispatch) => {
  return {
    _pduck: bindActionCreators(pduck.actions, dispatch),
    _sduck: bindActionCreators(sduck.actions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);

