import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";

import { Table } from "react-bootstrap";

export default function Fences() {
  return (
    <>
      <Portlet>
        <PortletHeader
          title={
            <>
              Fence List{" "}
              <small>Geo fence manager.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <a
                href="/#"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
              >
                Add New
              </a>
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td colSpan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex justify-content-end">
            <div className="d-flex flex-wrap">
              <a href="/#" className="btn btn-icon btn-sm btn-secondary mr-2 my-1">«</a>
              {/* <a href="/#" className="btn btn-icon btn-sm btn-secondary mr-2 my-1">‹</a> */}
              {/* <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a> */}

              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">1</a>
              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">2</a>
              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">3</a>
              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">4</a>
              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">5</a>
              <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">6</a>

              {/* <a href="/#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a> */}
              {/* <a href="/#" className="btn btn-icon btn-sm btn-secondary mr-2 my-1">›</a> */}
              <a href="/#" className="btn btn-icon btn-sm btn-secondary mr-2 my-1">»</a>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}
