import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/_expenses.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  rider: null,

  list: [],
  toastErr: null
}

const persistConfig = {
  storage,
  key: 'elves-expenses',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state._expenses.page
export const getCurrentRider = (state) => state._expenses.rider

// -- action types

export const actionTypes = {
  EXPENSES2_REQ: 'EXPENSES2_REQ',
  EXPENSES2_LOAD: 'EXPENSES2_LOAD',

  EXPENSE2_UPD_REQ: 'EXPENSE2_UPD_REQ',
  EXPENSE2_UPD_LOAD: 'EXPENSE2_UPD_LOAD',
}

// -- actions

export const actions = {
  getRecords: (page, rider) => ({ type: actionTypes.EXPENSES2_REQ, page, rider }),
  fillRecords: payload => ({ type: actionTypes.EXPENSES2_LOAD, payload }),

  patchRecord: (_id, data) => ({ type: actionTypes.EXPENSE2_UPD_REQ, payload: { _id, data } }),
  fillRecord: payload => ({ type: actionTypes.EXPENSE2_UPD_LOAD, payload }),
  // doSearch: text => ({ type: actionTypes.RIDERS2_SEARCH_REQ, text }),

  // setToastErr: err => ({ type: actionTypes.RIDER2_TOAST_ERR, err }),
  // toastClear: () => ({ type: actionTypes.RIDER2_TOAST_CLEAR }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.EXPENSES2_REQ, function* getSaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const rider = yield select(getCurrentRider);

      const { data: response } = yield crud.getMany(page, rider);

      yield put(actions.fillRecords(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  })

  yield takeLatest(actionTypes.EXPENSE2_UPD_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillRecord(response))
    } catch (err) {
      yield put(actions.setToastErr(err))
    }
  });

  // -- reducers
}

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPENSES2_REQ: {
      return {
        ...state,
        page: action.page,
        rider: action.rider,
      };
    }

    case actionTypes.EXPENSES2_LOAD: {
      const { page, pages, count, data: list } = action.payload;
      return { ...state, count, page, pages, list };
    }

    case actionTypes.EXPENSE2_UPD_LOAD: {
      const { _id } = action.payload
      const { list } = state

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          break
        }
      }

      return { ...state }
    }

    default:
      return state;
  }
});
