import axios from 'axios';
import { API_SERVER } from '../../config'

const LIMIT = 12
const SORT = 'name'

export const API_RESOURCE = `${API_SERVER}/stores`

export function getMany(page = 1) {
  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&sort=${SORT}`);
}

export function search(text) {
  return axios.get(`${API_RESOURCE}?search=${text}&limit=${LIMIT}&sort=${SORT}`);
}

export function post(data) {
  return axios.post(`${API_RESOURCE}`, data);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}`, data);
}

export function read(_id) {
  return axios.get(`${API_RESOURCE}/${_id}`);
}

// export function query(queryString, limit) {
//   return axios.get(`${API_RESOURCE}?${queryString}&limit=${limit || LIMIT}&sort=name`);
// }

export function query(query) {
  const { isActive, sort, page, limit } = query

  let queryString = ''

  if (isActive) queryString += `&isActive=${isActive}`

  return axios.get(`${API_RESOURCE}?page=${page || 1}&limit=${limit || LIMIT}&sort=${sort || SORT}${queryString}`);
}