import axios from 'axios';
import { API_SERVER } from '../../config'

export const API_RESOURCE = `${API_SERVER}/orders`

const LIMIT = 12
const EXPAND = 'store,marker,owner,items.product.addOns,rider';

export function read(_id) {
  return axios.get(`${API_RESOURCE}/${_id}?expand=${EXPAND}`);
}

export function readMany(page = 1) {
  return axios.get(`${API_RESOURCE}?page=${page}&limit=${LIMIT}&sort=-createdAt&expand=${EXPAND}`);
}

export function patch(_id, data) {
  return axios.patch(`${API_RESOURCE}/${_id}?expand=${EXPAND}`, data);
}
