import * as sduck from './_shops.duck'
import * as uduck from './_users.duck'
import * as mduck from './_markers.duck'

import { put, take } from 'redux-saga/effects'

export const extractIds = (arrObj, ...arrFields) => {
	const ret = {};

	for (const obj of arrObj) {
		for (const field of arrFields) {
			if (obj[field]) {
				if (!ret[field]) {
					ret[field] = new Set([obj[field]]);
				} else {
					ret[field].add(obj[field]);
				}
			}
		}
	}

	for (const field of arrFields) {
		if (ret[field]) {
			ret[field] = Array.from(ret[field]);
		}
	}

	return ret;
};

export const getNotInRecsQry = (mapLkUp, ids) => {
	if (!ids) {
		return false;
	}

	const notInRecs = ids
    .map(id => (!mapLkUp.has(id) ? id : null ))
    .filter(Boolean);

	return notInRecs.length
		? `_id=${notInRecs.join('&_id=')}`
		: false;
};


export function* expand (state, arrObj, ...arrFields) {
	const extracted = extractIds(arrObj, ...arrFields);

	for (const field of arrFields) {
		const ids = extracted[field]

		switch (field) {
			case 'shop': {
				const ninRecQry = getNotInRecsQry(state._shops.lookup, ids);

				if (ninRecQry) {
					yield put(sduck.actions.lookup(ninRecQry))
					yield take(sduck.actionTypes.SHOPS_LKUP_DONE)
				}

				break;
			}

			case 'owner':
			case 'rider': {
				const ninRecQry = getNotInRecsQry(state._users.lookup, ids);

				if (ninRecQry) {
					yield put(uduck.actions.lookup(ninRecQry))
					yield take(uduck.actionTypes.USERS_LKUP_DONE)
				}

				break;
			}

			case 'destination': {
				const ninRecQry = getNotInRecsQry(state._markers.lookup, ids);

				if (ninRecQry) {
					yield put(mduck.actions.lookup(ninRecQry))
					yield take(mduck.actionTypes.MARKERS_LKUP_DONE)
				}

				break;
			}

			default: {}
		}
	}

	for (const rec of arrObj) {
		for (const field of arrFields) {
			if (rec[field]) {
				switch (field) {
					case 'shop':
						rec[field] = state._shops.lookup.get(rec[field]);
						break;

					case 'destination':
						rec[field] = state._markers.lookup.get(rec[field]);
						break;
					
					case 'owner':
					case 'rider':
						rec[field] = state._users.lookup.get(rec[field]);
						break;

					default: {}
				}
			}
		}
	}
}