/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';

import {
  Form, Col, Row, Container,
  Table, Modal, Button, Dropdown, Spinner,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import * as duck from '../../../store/ducks/stores.duck';
import Pagination from '../../../partials/component/Pagination';

const DEFAULT_PHOTO = 'https://res.cloudinary.com/higher-elves/image/upload/v1626921093/stores/sh_icycr1_od5jqq.webp';

function Stores(props) {
  const { getStores, stores, doSearch, patchStore, addStore } = props;
  const currentPage = stores.page || 1;

  const [editStore, setEditStore] = useState(null)
  const [showModal, setShowModal] = useState(false)

  // -- hooks

  useEffect(() => {
    getStores(currentPage)
  }, [getStores, currentPage])

  // -- helpers

  const to12h = (mTime) => {
    const ampm = mTime < 1300 ? 'AM' : 'PM';

    if (mTime >= 1300) {
      mTime = mTime - 1200;
    }

    const str = mTime < 1000 ? `0${mTime}` : `${mTime}`;
    const time = `${str[1]}:${str[2]}${str[3]} ${ampm}`;

    return +str[0] ? `${str[0]}${time}` : time;
  };

  const isStoreOpen = (storeHrs) => {
    const dt = new Date();
    const mm = dt.getMinutes();
    const time = +`${dt.getHours()}${mm > 9 ? mm : `0${mm}`}`;

    if (storeHrs[0] < storeHrs[1]) {
      return time > storeHrs[0] && time < storeHrs[1];
    } else {
      const isBelowClosing = time > -1 && time < storeHrs[1];
      const isAheadFromOpening = time > storeHrs[0] && time < 2400;

      return isAheadFromOpening || isBelowClosing;
    }
  };

  // -- user actions

  const handlePressEnter = (event) => {
    if(event.key === 'Enter') {
      const text = event.target.value
    
      if (text.length > 1) {
        doSearch(text)
      } else {
        getStores(currentPage)
      }
    }
  }

  const updateStore = (_id, data) => {
    patchStore(_id, data)
  }

  const onEditStore = (store) => {
    setEditStore(store)
    setShowModal(true)
  }

  const handleModalSubmit = (data) => {
    if (editStore) {
      updateStore(editStore._id, data)
    } else {
      addStore(data)
    }
  }

  // -- render

  return (
    <>
      <StoreModal
        show={showModal}
        store={editStore}
        onHide={(cb) => setShowModal(false)}
        onSubmit={data => handleModalSubmit(data)}
      />

      <Portlet>
        <PortletHeader
          title={
            <>
              Store List{' '}
              <small>All registered stores.</small>
            </>
          }

          toolbar={
            <PortletHeaderToolbar>
              <Form.Control size="sm" type="text" placeholder="Search Store" onKeyPress={handlePressEnter} />

              &nbsp;
              &nbsp;
              <Button
                variant="secondary"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h"
                onClick={() => { setShowModal(true); setEditStore(null)}} >Add&nbsp;New
              </Button>
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID ({stores.count})</th>
                <th>Name</th>
                <th>Smart/Globe</th>
                <th>Likes</th>
                <th>Store Hours</th>
                <th>Status</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                Array.from(stores.list.values()).map((item, index) => {
                  const isOpen = isStoreOpen(item.storeHours)

                  return (
                    <tr key={item._id}>
                      <td>
                        <a href="#" className="kt-footer__menu-link kt-link" onClick={() => onEditStore(stores.list.get(item._id))}>
                          {item._id.substr(0, 6)}
                        </a>
                      </td>
                      <td>
                        <img alt="" className="product-image" src={item.photo} />&nbsp;&nbsp;
                        {item.name}
                      </td>
                      <td>{item.phoneSmart === '09000000000' ? '??' : item.phoneSmart} / {item.phoneGlobe === '09000000000' ? '??' : item.phoneGlobe}</td>
                      <td>{item.likeCount}</td>
                      <td>
                        {
                          <span >
                            {`${to12h(item.storeHours[0])} - ${to12h(item.storeHours[1])} `}
                          </span>
                        }
                      </td>
                      <td>
                        {
                          item.forcedClosed
                            ? <span className="kt-font-danger">FORCED CLOSED</span>
                            : <span className={isOpen ? '' : 'kt-font-danger'}>{ isOpen ? 'OPEN ' : 'CLOSED' }</span>
                        }
                      </td>
                      <td>
                        {
                          item.isActive
                            ? <span className="">Active</span>
                            : <span className="kt-font-danger">Inactive</span>
                        }
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                            Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => updateStore(item._id, { isActive: !item.isActive })}>{ item.isActive ? 'Set as Inactive' : 'Set as Active'}</Dropdown.Item>
                            <Dropdown.Item onClick={() => updateStore(item._id, { forcedClosed: !item.forcedClosed })}>{ item.forcedClosed ? 'Clear Forced Closed' : 'Force Closed'}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          { stores.isLoading && (
            <div className="center-hv">
              <Spinner animation="border" variant="primary" />
              <span>&nbsp;&nbsp;Fetching Data..</span>
            </div>
          )}

          { !stores.isLoading && !stores.list.size && (
            <div className="center-hv">
              <span>No records found!</span>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <Pagination
              page={stores.page}
              pages={stores.pages}
              onPageClick={getStores}
            />
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
}

function StoreModal (props) {
  const { store } = props
  const [errorMsg, setErrorMsg] = useState('')

  const [name, setName] = useState(store?.name || '')
  const [type, setType] = useState(store?.type || 'foods')
  const [desc, setDesc] = useState(store?.description || '')
  const [cats, setCats] = useState(store?.menuCategories.join(', ') || '')
  const [photo, setPhoto] = useState(store?.photo || DEFAULT_PHOTO)
  const [rank, setRank] = useState(store?.rank || 99)
  const [smartNum, setSmartNum] = useState(store?.phoneSmart || '09000000000')
  const [globeNum, setGlobeNum] = useState(store?.phoneGlobe || '09000000000')

  const [storeOpen, setStoreOpen] = useState(store?.storeHours.length ? store?.storeHours[0] : 1000)
  const [storeClose, setStoreClose] = useState(store?.storeHours.length ? store?.storeHours[1] : 2100)

  useEffect(() => {
    if (store) {
      setName(store.name)
      setType(store.type)
      setRank(store.rank)
      setPhoto(store.photo)
      setSmartNum(store.phoneSmart)
      setGlobeNum(store.phoneGlobe)
      setDesc(store.description)
      setCats(store.menuCategories.join(', '))
      setStoreOpen(store.storeHours.length ? store.storeHours[0] : 1000)
      setStoreClose(store.storeHours.length ? store.storeHours[1] : 2100)
    }
  }, [store]);

  // -- user actions

  const handleSubmit = () => {
    if (!name) {
      setErrorMsg('Please enter product name.')
      return
    }

    if (!desc) {
      setErrorMsg('Please enter product description.')
      return
    }

    if (!storeOpen || !storeClose) {
      setErrorMsg('Please enter a valid store hours.')
      return
    }

    setErrorMsg('')

    const capturedValues = {
      name,
      type,
      photo,
      description: desc,
      phoneGlobe: globeNum,
      phoneSmart: smartNum,
      storeHours: [+storeOpen, +storeClose],
      menuCategories: cats.split(',').map(s => s.trim()),
      rank: +rank || 99,
    }

    if (store) {
      props.onSubmit(capturedValues)
    } else {
      props.onSubmit({
        ...capturedValues,
  
        isActive: false,
        address: 'Bulan, Sorsogon',
        areaGroup: 'BULAN_G0',
        servicesOffered: ['DELIVER'],
        coordinates: [ 123.874531200549,  12.6661201463605]
      })
    }

    handleHide()
  }

  const handleHide = () => {
    setName('')
    setDesc('')
    setCats('')
    setStoreOpen(1000)
    setStoreClose(2100)
    setSmartNum('09000000000')
    setGlobeNum('09000000000')
    
    props.onHide()
  }

  const handleEnterKey = (event) => {
    if(event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" size="lg" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{ store ? 'Edit' : 'Add New'} Store { !!store && (<small> / {store._id}</small>)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        { !!errorMsg && <code>{errorMsg}</code>}

        <Container className="mt-3">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>Store Name</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Store Name" onChange={(e) => setName(e.target.value)} value={name} /></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Description</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Description" onChange={(e) => setDesc(e.target.value)} value={desc} onKeyPress={handleEnterKey}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Menu Cat</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Comma delimited." onChange={(e) => setCats(e.target.value)} value={cats} onKeyPress={handleEnterKey}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Photo</Form.Label>
              <Col sm={10}><Form.Control type="text" placeholder="Photo" onChange={(e) => setPhoto(e.target.value)} value={photo} onFocus={(event) => event.target.select()}/></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Store Hours</Form.Label>
              <Col sm={4}>
                <Row>
                  <Col><Form.Control type="number" onChange={(e) => setStoreOpen(e.target.value)} value={storeOpen} /></Col>
                  <Col><Form.Control type="number" onChange={(e) => setStoreClose(e.target.value)} value={storeClose} /></Col>
                </Row>
              </Col>

              <Col sm={1}></Col>
              <Form.Label column sm={1}>Rank</Form.Label>
              <Col sm={3}>
                <Form.Control type="text" placeholder="Rank" onChange={(e) => setRank(e.target.value)} value={rank} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Smart/TNT</Form.Label>
              <Col sm={4}>
                <Row>
                  <Col><Form.Control type="text" onChange={(e) => setSmartNum(e.target.value)} value={smartNum} /></Col>
                </Row>
              </Col>

              <Form.Label column sm={2}>Globe/TM</Form.Label>
              <Col sm={3}>
                <Row>
                  <Col><Form.Control type="text" onChange={(e) => setGlobeNum(e.target.value)} value={globeNum} /></Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>Type</Form.Label>
              <Col sm={5}>
                <Form.Control as="select" onChange={(e) => setType(e.target.value)} value={type}>
                  <option key="foods">foods</option>
                  <option key="goods">goods</option>
                  <option key="apparels">apparels</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={2}>Order Type</Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-dine-in" label="DINE IN" />
                      <Form.Check type="checkbox" id="checkbox-take-out" label="TAKE OUT" />
                    </Col>
                    <Col sm={3}>
                      <Form.Check type="checkbox" id="checkbox-pick-up" label="PICK UP" />
                      <Form.Check type="checkbox" id="checkbox-deliver" label="DELIVER" />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSubmit} variant="primary" className="btn-sm">{ store ? 'Save' : 'Submit'}</Button>
        <Button onClick={handleHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		stores: state.stores,
	}
}

export default connect(mapStateToProps, duck.actions)(Stores);
