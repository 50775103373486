import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/stores.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),
  products: new Map(),

  currentId: null,
  isLoading: true,
}

const persistConfig = {
  storage,
  key: 'elves-stores',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.stores.page
export const getCurrentId= (state) => state.stores.currentId

// -- action types

export const actionTypes = {
  STORE_ADD: 'STORE_ADD',

  STORE_REQ: 'STORE_REQ',
  STORE_LOAD: 'STORE_LOAD',

  STORES_REQ: 'STORES_REQ',
  STORES_LOAD: 'STORES_LOAD',
  
  STORES_QUERY_REQ: 'STORES_QUERY_REQ',
  STORES_SEARCH_REQ: 'STORES_SEARCH_REQ',
  STORES_UPDATE_REQ: 'STORES_UPDATE_REQ',
}

// -- actions

export const actions = {
  addStore: payload => ({ type: actionTypes.STORE_ADD, payload }),

  getStores: (page) => ({ type: actionTypes.STORES_REQ, page }),
  fillStores: response => ({ type: actionTypes.STORES_LOAD, payload: { response } }),

  doSearch: text => ({ type: actionTypes.STORES_SEARCH_REQ, text }),
  doQuery: query => ({ type: actionTypes.STORES_QUERY_REQ, query }),

  getStore: (_id) => ({ type: actionTypes.STORE_REQ, _id }),
  patchStore: (_id, data) => ({ type: actionTypes.STORES_UPDATE_REQ, payload: { _id, data } }),
  fillStore: payload => ({ type: actionTypes.STORE_LOAD, payload }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.STORES_REQ, function* getSaga() {
    try {
      const page = yield select(getCurrentPage);

      const { data: response } = yield crud.getMany(page);
      yield put(actions.fillStores(response));  
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.STORES_QUERY_REQ, function* getActivesSaga(action) {
    try {
      const { data: response } = yield crud.query(action.query)
      yield put(actions.fillStores(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.STORES_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillStores(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.STORES_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillStore(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.STORE_REQ, function* getStoreSaga() {
    try {
      const _id = yield select(getCurrentId)
      const { data: response } = yield crud.read(_id)

      yield put(actions.fillStore(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.STORE_ADD, function* postSaga(action) {
    try {
      const { data: response } = yield crud.post(action.payload)
      yield put(actions.fillStore(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORES_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.page
      };
    }

    case actionTypes.STORE_REQ:{
      return {
        ...state,
        isLoading: true,
        currentId: action._id
      }
    }

    case actionTypes.STORES_LOAD: {
      const { page, pages, count, data } = action.payload.response;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    case actionTypes.STORE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      list.set(_id, action.payload)

      return { ...state, list, isLoading: false, }
    }

    default:
      return state;
  }
});
