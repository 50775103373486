import React, { useEffect, useState } from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../partials/content/Portlet';

import {
  Table, Button,
  Dropdown, Container,
  Modal, Form, Col, Row,
} from 'react-bootstrap';

import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import * as duck from '../../../store/ducks/orders.duck'
import Pagination from '../../../partials/component/Pagination';

const STATUSES = [
  'PENDING',
  'CONFIRMED',
  'PROCESSING',
  'READY',
  'SERVED',
  'CANCELLED',
  'UNCLAIMED'
]

const STATUS_COLORS = {
  PENDING: 'secondary',
  CONFIRMED: 'primary',
  PROCESSING: 'warning',
  READY: 'success',
  SERVED: 'secondary',
  CANCELLED: 'danger',
  UNCLAIMED: 'danger'
}

function Orders(props) {
  const { getOrders, patchOrder, orders } = props;
  const currentPage = orders.page || 1;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // -- hooks

  useEffect(() => {
    getOrders(currentPage)
  }, [getOrders, currentPage])

  // -- user actions

  const updateOrderStatus = (_id, status) => {
    if (status === 'CANCELLED') {
      setModalVisible(true)
      setSelectedOrder(_id)
    } else {
      patchOrder(_id, { status })
    }
  }

  // -- render

  return (
    <>
      <Portlet>
        <PortletHeader
          title={<>Orders{' '}<small>All store order manager.</small></>}

          toolbar={
            <PortletHeaderToolbar>
              {/* <a href='/#' className='btn btn-label-success btn-bold btn-sm btn-icon-h' >
                Add New
              </a> */}
            </PortletHeaderToolbar>
          }
        />

        <PortletBody>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Store</th>
                <th>User</th>
                <th>Nearest Landmark</th>
                <th>Rider</th>
                <th>Items</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                {/* <th>Rider</th>
                <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {
                orders.list.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <Link to={`/orders/${item._id}`} className="kt-footer__menu-link kt-link">
                          { item.archived ? '🔒 ' : ''} {item._id.substr(0, 6)}
                        </Link>
                      </td>
                      <td>
                        {item.store.name}
                        {
                          item.groupId
                            ? <span className="kt-font-danger">{` [${item.groupId.substr(1, 4)}] `}</span>
                            : ''
                        }
                      </td>
                      <td>({item.owner.txnCount}) {item.owner.name}</td>
                      <td>{item.marker.name}</td>
                      <td>{item.rider?.name}</td>
                      <td>{item.items.length}</td>
                      <td>₱ {(item.totalPrice + item.deliveryFee).toFixed(2)}</td>
                      <td>
                        <TimeAgo date={item.createdAt}/>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle variant={STATUS_COLORS[item.status]} id="dropdown-basic" size="sm">
                            {item.status}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {
                              STATUSES.map(status => {
                                if (status !== item.status) {
                                  return <Dropdown.Item key={`${item._id}-${status}`} onClick={() => updateOrderStatus(item._id, status)}>{status}</Dropdown.Item>
                                } else {
                                  return undefined;
                                }
                              })
                            }

                            <Dropdown.Item key={`${item._id}-archive`} onClick={() => patchOrder(item._id, { archived: !item.archived })}>{item.archived ? 'UNARCHIVE' : 'ARCHIVE' }</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      {/* <td></td>
                      <td></td> */}
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

          <div className='d-flex justify-content-end'>
            <Pagination
              page={orders.page}
              pages={orders.pages}
              onPageClick={getOrders}
            />
          </div>

        </PortletBody>
      </Portlet>

      <CancelReasonModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        onSubmit={reason => {
          patchOrder(selectedOrder, {
            status: 'CANCELLED',
            cancelReason: `${reason}\n**Cancelled by Admin**`
          })

          setModalVisible(false)
        }}
      />
    </>
  );
}

function CancelReasonModal(props) {
  const [reason, setReason] = useState('')

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" {...props} centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cancel Reason</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className="mt-3">
          <Form onSubmit={props.onSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>Reason</Form.Label>
              <Col sm={10}>
                <Form.Control
                  placeholder="Enter Reason.."
                  onChange={e => setReason(e.target.value)}
                />
              </Col>

            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onSubmit(reason)} variant="primary" className="btn-sm" type="submit">Submit</Button>
        <Button onClick={props.onHide} variant="secondary" className="btn-sm">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => {
	return {
		orders: state.orders,
	}
}

export default connect(mapStateToProps, duck.actions)(Orders);
