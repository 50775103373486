import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/addons.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: [],
}

const persistConfig = {
  storage,
  key: 'elves-addons',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.addons.page

// -- action types

export const actionTypes = {
  ADDONS_REQ: 'ADDONS_REQ',
  ADDONS_LOAD: 'ADDONS_LOAD',
  ADDON_LOAD: 'ADDON_LOAD',

  ADDONS_SEARCH_REQ: 'ADDONS_SEARCH_REQ',
  ADDONS_UPDATE_REQ: 'ADDONS_UPDATE_REQ',
}

// -- actions

export const actions = {
  getAddons: (page) => ({ type: actionTypes.ADDONS_REQ, page }),
  fillAddons: response => ({ type: actionTypes.ADDONS_LOAD, payload: { response } }),

  doSearch: text => ({ type: actionTypes.ADDONS_SEARCH_REQ, text }),

  patchAddon: (_id, data) => ({ type: actionTypes.ADDONS_UPDATE_REQ, payload: { _id, data } }),
  fillAddon: payload => ({ type: actionTypes.ADDON_LOAD, payload }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.ADDONS_REQ, function* getSaga() {
    try {
      const page = yield select(getCurrentPage);

      const { data: response } = yield crud.getMany(page);
      yield put(actions.fillAddons(response));  
    } catch (err) {
      console.log('--x:', err) // TODO: toast
    }
  });

  yield takeLatest(actionTypes.ADDONS_SEARCH_REQ, function* searchSaga(action) {
    try {
      const { data: response } = yield crud.search(action.text)
      yield put(actions.fillAddons(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });

  yield takeLatest(actionTypes.ADDONS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillAddon(response))
    } catch (err) {
      console.log('--axiosErr:', err) // TODO: toast
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADDONS_REQ: {
      return { ...state, page: action.page };
    }

    case actionTypes.ADDONS_LOAD: {
      const { page, pages, count, data: list } = action.payload.response;
      return { ...state, count, page, pages, list };
    }

    case actionTypes.ADDON_LOAD: {
      const { _id } = action.payload
      const { list } = state

      let found = false;

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id === _id) {
          list[i] = action.payload
          found = true
          break
        }
      }

      if (!found) {
        list.push(action.payload)
      }

      return { ...state }
    }

    default:
      return state;
  }
});
