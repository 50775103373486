import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as crud from '../../crud/products.crud'

const initialState = {
  page: 1,
  pages: 0,
  count: 0,
  list: new Map(),

  isLoading: true
}

const persistConfig = {
  storage,
  key: 'elves-products',
  blacklist: Object.keys(initialState)
}

export const getCurrentPage = (state) => state.products.page

// -- action types

export const actionTypes = {
  PRODUCT_ADD: 'PRODUCT_ADD',
  PRODUCTS_LOAD: 'PRODUCTS_LOAD',

  PRODUCTS_UPDATE_REQ: 'PRODUCTS_UPDATE_REQ',
  PRODUCTS_UPDATE_LOAD: 'PRODUCTS_UPDATE_LOAD',

  PRODUCTS_QUERY_REQ: 'PRODUCTS_QUERY_REQ',
}

// -- action

export const actions = {
  addProduct: payload => ({ type: actionTypes.PRODUCT_ADD, payload }),
  fillProducts: payload => ({ type: actionTypes.PRODUCTS_LOAD, payload }),

  patchProduct: (_id, data) => ({ type: actionTypes.PRODUCTS_UPDATE_REQ, payload: { _id, data } }),
  fillProduct: payload => ({ type: actionTypes.PRODUCTS_UPDATE_LOAD, payload }),

  doQuery: query => ({ type: actionTypes.PRODUCTS_QUERY_REQ, query }),
};

// -- sagas

export function* saga() {
  yield takeLatest(actionTypes.PRODUCTS_UPDATE_REQ, function* patchSaga(action) {
    const { _id, data } = action.payload

    try {
      const { data: response } = yield crud.patch(_id, data)
      yield put(actions.fillProduct(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.PRODUCTS_QUERY_REQ, function* querySaga(action) {
    try {
      const page = yield select(getCurrentPage);
      const { data: response } = yield crud.query(action.query, page)

      yield put(actions.fillProducts(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });

  yield takeLatest(actionTypes.PRODUCT_ADD, function* postSaga(action) {
    try {
      const { data: response } = yield crud.post(action.payload)
      yield put(actions.fillProduct(response))
    } catch (err) {
      console.log('[TODO] toast:', err)
    }
  });
}

// -- reducers

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCTS_QUERY_REQ: {
      return {
        ...state,
        isLoading: true,
        page: action.query.page };
    }

    case actionTypes.PRODUCTS_LOAD: {
      const { page, pages, count, data } = action.payload;
      const { list } = state

      list.clear()

      data.forEach(item => {
        list.set(item._id, item)
      });

      return {
        ...state,
        isLoading: false,
        count, page, pages, list,
      };
    }

    case actionTypes.PRODUCTS_UPDATE_LOAD: {
      const { _id } = action.payload
      const { list } = state

      list.set(_id, action.payload)

      return { ...state, list, isLoading: false, }
    }

    default:
      return state;
  }
});
